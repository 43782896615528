import { Warehouses } from "@/types/Warehouses";
import api, { ApiResponse } from "./ApiService";

export async function getWarehouses(
  params: {
    page?: number;
    pageSize?: number;
    groups?: number;
    hasBalance?: boolean;
    search?: string;
  } = {}
): Promise<ApiResponse<Warehouses[]>> {
  try {
    const response = await api.get("/warehouses/list", {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        search: params.search,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function getWarehouseById(
  id: string
): Promise<ApiResponse<Warehouses>> {
  try {
    const response = await api.get(`warehouses/show/${id}`);
    return response.data;
  } catch (error) {
    console.log("Api Error", error);
    throw error;
  }
}

export async function createWarehouse(warehouse: Warehouses) {
  try {
    const response = await api.post("/warehouses/create", {
      ...warehouse,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateWarehouse(warehouse: Warehouses, id: string) {
  try {
    const response = await api.put(`/warehouses/update/${id}`, {
      name: warehouse.name,
      address: warehouse.address,
      city_id: warehouse.city_id || undefined,
      county_id: warehouse.county_id || undefined,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getShelvesByWarehouseId(id: string) {
  try {
    const response = await api.get(`/warehouses/shelves/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteWarehouse(id: any) {
  try {
    const response = await api.delete(`/warehouses/delete/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
