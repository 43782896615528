import ModelsView from "@/views/Models/ModelsView.vue";

export const ModelsRoutes = [
  {
    path: "/models",
    name: "ModelsList",
    component: ModelsView,
  },
  {
    path: "/models/create",
    name: "ModelCreate",
    component: () => import("@/views/Models/ModelsForm.vue"),
  },
  {
    path: "/models/edit/:id",
    name: "ModelEdit",
    component: () => import("@/views/Models/ModelsForm.vue"),
  },
];
