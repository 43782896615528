import api, { ApiResponse } from "./ApiService";
import { Products } from "@/types/ProductsTypes";

/**
 * Get products
 * @param params
 * @returns
 */
export async function getProducts(params?: {
  page?: number;
  pageSize?: number;
  search?: string;
}): Promise<ApiResponse<Products[]>> {
  try {
    const response = await api.get("items/list", {
      params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Get product
 * @param id
 * @returns
 */
export async function getProduct(id: string): Promise<ApiResponse<Products>> {
  try {
    const response = await api.get(`items/show/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getProductOnly(id: string): Promise<ApiResponse<Products>> {
  try {
    const response = await api.get(`items/show/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Create product
 * @param data
 * @returns
 */
export async function createProduct(
  data: Products
): Promise<ApiResponse<Products>> {
  try {
    const response = await api.post("items/create", {
      ...data,
      type: "product",
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Update product
 */
export async function updateProduct(
  id: string,
  data: Products
): Promise<ApiResponse<Products>> {
  try {
    delete data.id;
    delete data.company_id;
    const response = await api.put(`items/update/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Delete product
 */
export async function deleteProduct(
  id: string
): Promise<ApiResponse<Products>> {
  try {
    const response = await api.delete(`products/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
