import ShelvesView from "@/views/Shelves/ShelvesView.vue";

export const ShelvesRoutes = [
  {
    path: "/shelves",
    name: "ShelvesList",
    component: ShelvesView,
  },
  {
    path: "/shelves/details/:id",
    name: "ShelvesDetails",
    component: () => import("@/views/Shelves/ShelvesView.vue"),
  },
  {
    path: "/shelves/create",
    name: "ShelvesCreate",
    component: () => import("@/views/Shelves/ShelvesForm.vue"),
  },
  {
    path: "/shelve/edit/:id",
    name: "ShelvesEdit",
    component: () => import("@/views/Shelves/ShelvesForm.vue"),
  },
];
