import { ProductCategories } from "@/types/ProductCategories";
import api, { ApiResponse } from "./ApiService";

export async function getProductCategories(params?: {
  page?: number;
  pageSize?: number;
  search?: string;
}): Promise<ApiResponse<ProductCategories[]>> {
  try {
    const response = await api.get("/item-categories/list", {
      params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getProductCategory(
  id: string
): Promise<ApiResponse<ProductCategories>> {
  try {
    const response = await api.get(`/item-categories/show/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createProductCategory(
  data: ProductCategories
): Promise<ApiResponse<ProductCategories>> {
  try {
    const response = await api.post("item-categories/create", data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateProductCategory(
  id: string,
  data: ProductCategories
): Promise<ApiResponse<ProductCategories>> {
  try {
    const response = await api.put(`item-categories/update/${id}`, {
      name: data.name,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteProductCategory(
  id: string
): Promise<ApiResponse<ProductCategories>> {
  try {
    const response = await api.delete(`/item-categories/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
