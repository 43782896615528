<template>
  <nav class="d-flex justify-content-end pagination-nav">
    <ul class="pagination mb-0 mt-4">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <span class="page-link" @click.prevent="currentPage > 1 && updatePage(currentPage - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </span>
      </li>
      <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: page === currentPage }">
        <span class="page-link" @click.prevent="updatePage(page)">{{ page }}</span>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <span class="page-link" @click.prevent="currentPage < totalPages && updatePage(currentPage + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </span>
      </li>
    </ul>
  </nav>
</template>
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['pageChanged']);

const updatePage = (page : number) => {
  emit('pageChanged', page);
};

</script>
<style scoped>
.pagination {
  --bs-pagination-padding-x: 8px;
  --bs-pagination-padding-y: 3px;
  --bs-pagination-font-size: 13px;
}

.pagination-nav {
  padding-right: 16px;
}

.page-link {
  color: #999593;
  cursor: pointer;
}

.page-link:hover {
  color: #403637;
  background-color: #e8e7e7;
}

.page-link:focus {
  box-shadow: none;
  color: #403637;
  background-color: #e8e7e7;
}

.active>.page-link,
.page-link.active {
  background-color: #e8e7e7;
  border-color: #e8e7e7;
  color: #403637;
}

.pagination {
  cursor: not-allowed;
  --bs-pagination-disabled-bg: #f8f8f8;
}</style>