import { ActionContext, Module } from 'vuex';
import { RootState } from '../index'; 


interface CustomerData {
  firmaUnvani?: string;
  kisaIsim?: string;
  epostaAdresi?: string;
  telefonNumarasi?: number;
  faksNumarasi?: number;
  ibanNumarasi?: number;
  acikAdres?: string;
  postaKodu?: number;
  vknTckn?: number;
  tckn?: number;
}

interface State {
  customerData: CustomerData | null;
}


const state = (): State => ({
  customerData: null,
});


const mutations = {
  setCustomerData(state: State, data: CustomerData) {
    state.customerData = data;
  },
};


const actions = {
  updateCustomerData({ commit }: ActionContext<State, RootState>, data: CustomerData) {
    commit('setCustomerData', data);
  },
};


const getters = {
  getCustomerData: (state: State) => state.customerData,
};

const customerModule: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default customerModule;
