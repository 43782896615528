import ProductsView from "@/views/ProductCategories/CategoryView.vue";

export const ProductCategoriesRoutes = [
  {
    path: "/products-categories",
    name: "ProductsCategoriesList",
    component: ProductsView,
  },
  {
    path: "/products-categories/create",
    name: "ProductCategoryCreate",
    component: () => import("@/views/ProductCategories/CategoryForm.vue"),
  },
  {
    path: "/products-category/edit/:id",
    name: "ProductCategoryEdit",
    component: () => import("@/views/ProductCategories/CategoryForm.vue"),
  },
];
