<template>
  <div class="container">
    <div class="form-g-wrapper">
      <div class="inner-container">
        <div class="row align-items-center gap-2 mt-2">
          <div class="col-12 col-md d-flex align-items-center gap-1">
            <div class="dropdown btn-drop">
              <button class="btn btn-sm btn-flex btn-secondary fw-bold d-flex align-items-center gap-2" type="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-filter"></i>
                Filtrele
              </button>
              <ul class="dropdown-menu">
                <li><span @click="dueDateExpired = false, dueDateExpiring = false, paid = false">Tümü</span>
                </li>
                <li><span @click="dueDateExpired = true">Vadesi Geçmiş</span></li>
                <li><span @click="dueDateExpiring = true">Vadesi Yaklaşanlar</span></li>
                <li><span @click="paid = true">Ödenmiş</span></li>
              </ul>
            </div>

            <div class="input-group flex-grow-1 position-relative">
              <input type="text" class="form-control bg-secondary z-1 fs-4" placeholder="Ara..." v-model="search" />
              <div class="position-absolute top-50 translate-middle-y end-0 pe-3 z-3">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-auto mt-2 mt-md-0">
            <RouterLink :to="{ name: 'saleInvoiceCreate' }" class="new-btn btn btn-primary w-100">
              <i class="fa-solid fa-plus"></i>
              Yeni Alış Faturası Oluştur
            </RouterLink>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="d-flex justify-content-between">
            <div class="d-flex gap">
              <div v-if="dueDateExpiring" class="badge bg-warning" @click="dueDateExpiring = !dueDateExpiring">
                Vadesi Yaklaşanlar
                <i class="fa-solid fa-xmark"></i>
              </div>
              <div v-if="dueDateExpired" class="badge bg-danger" @click="dueDateExpired = !dueDateExpired">
                Vadesi Geçmiş
                <i class="fa-solid fa-xmark"></i>
              </div>
              <div v-if="paid" class="badge bg-success" @click="paid = !paid">
                Ödenmiş
                <i class="fa-solid fa-xmark"></i>
              </div>
              <div v-if="search" class="badge bg-primary" @click="search = null">
                Arama: {{ search }}
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
          </div>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="user-col"><input class="form-check-input checkbox-input ms-3" type="checkbox"
                  value=""></th>
              <th scope="col" class="text-uppercase">fatura açıklaması</th>
              <th scope="col" class="text-uppercase">fatura no</th>
              <th scope="col" class="text-uppercase">düzenleme tarihi</th>
              <th scope="col" class="text-uppercase">vade tarihi</th>
              <th scope="col" class="text-uppercase text-end">
                kalan meblağ
                <i class="fa-solid fa-arrow-down-short-wide"></i>
              </th>
              <th class="text-end">İşlemler</th>
            </tr>
          </thead>
          <tbody v-if="invoices.length">
            <tr v-for="invoice in invoices" :key="invoice.id">
              <th scope="row">
                <div class="d-flex align-items-center justify-content-center table-icon-box">
                  <i class="fa-solid fa-file-invoice"></i>
                </div>
              </th>
              <td>
                <div class="d-flex flex-column gap-1">

                  <div class="user-name">{{ invoice.description || '-' }}</div>
                  <small>{{ invoice.contact?.title || '-' }}</small>
                </div>
              </td>
              <td>{{ invoice.document_number ?? 'Belirtilmemiş' }}</td>
              <td>
                <div class="d-flex flex-column gap-1">
                  <div class="date">{{ dateHelper(invoice.issue_date) ?? '-' }}</div>
                  <div class="user-info d-flex gap-2">
                    <small class="e-mail">e-Arşiv Fatura</small>
                    <small class="badge d-flex align-items-center gap-1 text-uppercase">
                      <span class="dot"></span> Resmileştirildi
                    </small>
                  </div>
                </div>
              </td>
              <td>{{ dateHelper(invoice.due_date) ?? '-' }}</td>
              <td class="text-end">
                <div class="d-flex flex-column gap-1">
                  <div class="user-name" v-if="invoice.remaining == 0">Tahsil edildi</div>
                  <div class="user-name" v-if="invoice.remaining != 0">
                    {{ invoice.remaining }}
                    {{ invoice.currency?.symbol }}
                  </div>
                  <small>Genel Toplam {{ invoice.total ?? '-' }} {{
                    invoice.currency?.symbol
                    }}</small>
                </div>
              </td>
              <td class="text-end">
                <div class="dropdown">
                  <button class="btn btn-sm btn-secondary btn-active-light-primary show menu-dropdown" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <span>
                      <i class="fa-solid fa-ellipsis"></i>
                    </span></button>
                  <ul class="dropdown-menu fs-4 " aria-labelledby="speedBoxDropdown" style="">
                    <li>
                      <router-link class="dropdown-item" :to="{ name: 'documentDetail', params: { id: invoice.id } }">
                        <i class="fa-solid fa-eye me-2"></i>
                        Görüntüle
                      </router-link>
                    </li>

                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="invoices.length == 0">
            <tr>
              <td colspan="7" class="text-center">Kayıt bulunamadı</td>
            </tr>
          </tbody>
          <InvoiceSkeletor v-else></InvoiceSkeletor>
        </table>
        <PageNavigator :totalPages="totalPages" :currentPage="currentPage" @pageChanged="changePage" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import PageNavigator from "@/components/PageNavigator.vue";
import { handleApiError } from "@/helpers/errorHandling";
import { ref, onMounted, watch } from "vue";
import { dateHelper } from "@/helpers/generalHelpers";
import { getPurchaseInvoices } from "@/services/PurchaseInvoiceService";
import { Documents } from "@/types/Documents";
import InvoiceSkeletor from "@/components/Skeletors/InvoiceSkeletor.vue";
import { useRouter } from "vue-router";

const totalPages = ref(10);
const currentPage = ref(1);
const search = ref("");
const invoices = ref<Documents[]>([]);
const dueDateExpiring = ref(false);
const dueDateExpired = ref(false);
const paid = ref(false);
const router = useRouter();

watch([search, dueDateExpiring, dueDateExpired, paid], async () => {
  await fetchInvoices();
});

const changePage = async (page: number) => {
  currentPage.value = page;
};

const fetchInvoices = async () => {
  try {
    const response = await getPurchaseInvoices({
      page: currentPage.value,
      dueDateExpiring: dueDateExpiring.value,
      dueDateExpired: dueDateExpired.value,
      paid: paid.value,
    });
    invoices.value = response.data;
    totalPages.value = response.totalPages;

  } catch (error) {
    handleApiError(error);
  }
};

onMounted(async () => {
  await fetchInvoices();
});
</script>
<style></style>

<style scoped>
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #f2c94c;
}

.badge {
  background-color: #f7f7f6;
  border-radius: 8px;
}

small {
  color: #999593;
}

.btn-group {
  height: 40px;
  white-space: nowrap;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
 border-radius: 6px;
}

.input-group i {
  font-size: 20px;
  color: #999593;
}

.filter-btn {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  padding: 1.5px 30px 1.5px 15px;
  outline: 0;
  background-color: #d2d0cf;
  line-height: 40px;
  font-size: 1.3rem;
  font-weight: 600;
  color: #999593;
  transition: 0.5s;
}



.user-name::first-letter {
  text-transform: uppercase;
}

.user-info {
  font-size: 13px;
}

.user-col {
  width: 70px;
}


</style>