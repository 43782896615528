<template>
  <div class="container">
    <div class="form-g-wrapper ">
      <form @submit.prevent="onSubmit">
        <div class="inner-container d-flex flex-column gap-5">
          <div class="form-separator">
            <div class="form-wrapper ">
              <div class="row">
                <div class="col-3">
                  <div class="form-title d-flex align-items-center gap-5 ">
                    <i class="fa-solid fa-table"></i>
                    <span class="text-uppercase">Firma Ünvanı</span>
                  </div>
                </div>
                <div class="col-6">
                  <input type="text" name="title" class="form-input p-2" v-model="customer.title" />
                </div>
                <!-- 
                <div class="col-3">
                  <div class="button-wrapper d-flex align-items-center justify-content-end">
                    <button type="button" class="btn btn-outline-secondary btn-secondary"
                      @click="router.push({ name: 'CustomersList' })">Vazgeç</button>
                    <div class=" gap-1 btn-drop mx-2">
                      <button type="submit" class="btn btn-primary">Kaydet</button>
                      <button type="button" class="btn btn-secondary mx-2" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa-solid fa-ellipsis text-primary"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li><button @click="onSubmit">Kaydet ve yeni oluştur</button></li>
                      </ul>
                    </div>
                  </div>
                </div>
-->
              </div>
            </div>
          </div>

          <div class="form-separator d-flex flex-column gap-5">
            <div class="form-wrapper" v-if="!customerId">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-circle-info"></i>
                  <span class="text-uppercase">ID (veriler otomatik olarak eklenir)</span>
                </div>
                <div class="col-6">
                  <input class="form-input p-2" v-model="customerGetId" :label="'1'" />
                </div>
                <div class="col-2" v-if="customerGetId">
                  <button type="button" @click="customerFetch" class="button fill-btn rounded">Verileri Getir</button>
                </div>
              </div>
            </div>
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-circle-info"></i>
                  <span class="text-uppercase">kısa isim</span>
                </div>
                <div class="col-6">
                  <input name="short_name" type="text" class="form-input p-2" v-model="customer.short_name" />
                </div>
              </div>
            </div>
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-list"></i>
                  <span class="text-uppercase">Grup</span>
                </div>
                <div class="col-6">
                  <select class="form-select" aria-label="Default select example" v-model="customer.group_id">
                    <option :value="null" selected class="">Hiçbiri</option>
                    <option v-for="group in groups" :value="group.id" :key="group.id">{{ group.name }}</option>
                  </select>
                </div>


              </div>
            </div>
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-envelope"></i>
                  <span class="text-uppercase">e-posta adresi</span>
                </div>
                <div class="col-6">
                  <input name="email" type="text" class="form-input p-2 " v-model="customer.email" />
                </div>
              </div>
            </div>
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-phone"></i>
                  <span class="text-uppercase">telefon numarası</span>
                </div>
                <div class="col-6">
                  <input name="phone" type="text" class="form-input p-2 " v-model="customer.phone" />
                </div>
              </div>
            </div>
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-fax"></i>
                  <span class="text-uppercase">faks numarası</span>
                </div>
                <div class="col-6">
                  <input name="fax" type="text" class="form-input p-2 " v-model="customer.fax" />
                </div>
              </div>
            </div>
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-hashtag"></i>
                  <span class="text-uppercase">iban numarası</span>
                </div>
                <div class="col-6">
                  <input type="text" class="form-input p-2 " />
                </div>
              </div>
            </div>
          </div>

          <div class="form-separator d-flex flex-column gap-5">
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-location-dot"></i>
                  <span class="text-uppercase">açık adresi</span>
                </div>
                <div class="col-6">
                  <textarea name="address" class="form-input p-2 w-100" v-model="customer.address" />

                  <div class="form-check input-textarea p-2 d-flex align-items-center">
                    <input class="form-check-input checkbox-input ms-3" type="checkbox" value="" v-model="selectAddress"
                      id="flexCheckDefault">
                    <label class="form-check-label ms-3" for="flexCheckDefault">
                      Adres yurt dışında
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-wrapper" v-if="!selectAddress">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-signs-post"></i>
                  <span class="text-uppercase">posta kodu</span>
                </div>
                <div class="col-6">
                  <input name="post_code" type="text" class="form-input p-2" v-model="customer.zip_code" />
                  <ErrorMessage name="post_code" />
                </div>
              </div>
            </div>
            <div class="form-wrapper" v-if="!selectAddress">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-city"></i>
                  <span class="text-uppercase">ilçe, il</span>
                </div>
                <div class="col-6 d-flex gap-4">
                  <div class="input-group input-group-sm">
                    <span class="input-group-text">İl</span>
                    <select name="city_id" class="form-control position-relative" v-model="customer.city_id"
                      @change="updateCounties">
                      <option v-for="city in cities" :value="city.id" :key="city.id">{{ city.name }}</option>
                    </select>
                    <i class="fa-solid fa-magnifying-glass position-absolute top-50 translate-middle-y"></i>
                  </div>
                  <div class="input-group input-group-sm ">
                    <span class="input-group-text">İlçe</span>
                    <select name="county_id" class="form-control position-relative" v-model="customer.county_id">
                      <option v-for="county in counties" :value="county.id" :key="county.id">{{ county.name }}</option>
                    </select>
                    <i class="fa-solid fa-magnifying-glass position-absolute top-50 translate-middle-y"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-separator d-flex flex-column gap-5">
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-hammer"></i>
                  <span class="text-uppercase">türü</span>
                </div>
                <div class="col-6">
                  <div class="input-group">
                    <div class="form-check w-100 p-2 fist-check d-flex align-items-center">
                      <input name="type" type="radio" class="form-check-input ms-3 " :value="1"
                        v-model="customer.tax_type" id="flexRadio" />
                      <label class="form-check-label ms-3" for="flexRadio">
                        Tüzel Kişi
                      </label>
                    </div>
                    <div class="form-check w-100 p-2 d-flex align-items-center">
                      <input name="type" type="radio" class="form-check-input ms-3 " :value="2"
                        v-model="customer.tax_type" id="Radio" />
                      <label class="form-check-label ms-3" for="Radio">
                        Gerçek Kişi
                      </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-hammer" v-if="customer.tax_type === 1"></i>
                  <i class="fa-solid fa-person" v-if="customer.tax_type === 2"></i>
                  <span class="text-uppercase" v-if="customer.tax_type === 1">VKN/TCKN</span>
                  <span class="text-uppercase" v-if="customer.tax_type === 2">TC Kimlik No</span>
                </div>
                <div class="col-6">
                  <input type="text" class="form-input p-2" n v-if="customer.tax_type == 1"
                    v-model="customer.tax_number" />
                  <input type="text" class="form-input p-2" v-if="customer.tax_type == 2"
                    v-model="customer.tax_number" />
                </div>
              </div>
            </div>
            <div class="form-wrapper" v-if="customer.tax_type === 1">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-hammer"></i>
                  <span class="text-uppercase">vergi dairesi</span>
                </div>
                <div class="col-6">
                  <input type="text" class="form-input p-2" v-model="customer.tax_office" />
                </div>
              </div>
            </div>

          </div>
          <div class="form-separator d-flex flex-column gap-5">
            <div class="form-wrapper">
              <div class="row">
                <div class="col-3 d-flex gap-5 align-items-center">
                  <i class="fa-solid fa-rotate-right"></i>
                  <span class="text-uppercase">açılış bakiyesi</span>
                </div>
                <div class="col-6">
                  <div class="form-check input-textarea p-2 d-flex align-items-center">
                    <input class="form-check-input checkbox-input" type="checkbox" value="" id="flexCheck">
                    <label class="form-check-label ms-3" for="flexCheck">
                      Açılış bakiyesi var
                    </label>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="col-12">
            <div class="button-wrapper d-flex align-items-center justify-content-end">
              <button type="button" class="btn btn-outline-secondary btn-secondary"
                @click="router.push({ name: 'CustomersList' })">
                <i class="fa fa-times me-2"></i>
                Vazgeç
              </button>
              <div class=" gap-1 btn-drop mx-2">
                <button type="submit" class="btn btn-success">
                  <i class="fa fa-save me-2"></i>
                  Kaydet
                </button>
                <button type="button" class="btn btn-secondary mx-2" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa-solid fa-ellipsis text-primary"></i>
                </button>
                <ul class="dropdown-menu">
                  <li><button @click="onSubmit">Kaydet ve yeni oluştur</button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref, toRefs, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { handleApiError } from '@/helpers/errorHandling';

import { getGroups } from '@/services/CustomerGroupService';
import { getCustomerById, createCustomer, updateCustomer } from '@/services/CustomerService';
import { getCities, getCounties } from '@/services/UtilsService';

import { Customer } from '@/types/Customer';
import { City, County } from '@/types/UtilsTypes';
import { CustomerGroups } from '@/types/CustomerGroups';

import * as yup from 'yup';
import Swal from 'sweetalert2';
import { showSuccessMessage } from '@/helpers/swalHelper';

const router = useRouter();

const customerGetId = ref<number | null>(null);

const props = defineProps({
  isEditing: {
    type: Boolean,
    default: false
  },
  customerId: {
    type: Number,
    default: null,
  }
});

const schema = yup.object().shape({
  title: yup.string().required().label('Firma Ünvanı'),
  short_name: yup.string().nullable().label('Kısa İsim'),
  email: yup.string().email().nullable().label('E-posta Adresi'),
  phone: yup.string().nullable().label('Telefon Numarası'),
  address: yup.string().nullable().label('Açık Adresi'),
  city_id: yup.number().nullable().label('İl'),
  county_id: yup.number().nullable().label('İlçe'),
  tax_type: yup.number().nullable().label('Türü'),
  tax_number: yup.string().nullable().label('VKN/TCKN'),
  tax_office: yup.string().nullable().label('Vergi Dairesi'),
  balance: yup.number().nullable().label('Açılış Bakiyesi'),
  group_id: yup.number().nullable().label('Grup'),
  fax: yup.string().nullable().optional().label('Faks Numarası'),
});

const { customerId, isEditing } = toRefs(props);

const selectAddress = ref(false);
const cities = ref<City[]>([]);
const counties = ref([]) as Ref<County[]>;
const customer = ref<Customer>({
  title: '',
  type: 'customer',
});
const groups = ref([]) as Ref<CustomerGroups[]>;

const onSubmit = async () => {
  const isValid = await validate(customer.value);
  if (!isValid) return;

  try {
    if (isEditing.value) {
      await updateCustomer(customer.value);
    } else {
      await createCustomer(customer.value);
    }
    await showSuccessMessage('Müşteri başarıyla kaydedildi');
    router.back();
  } catch (error) {
    handleApiError(error);
  }
};


const validate = async (data: Customer) => {
  try {
    await schema.validate(data, { abortEarly: false });
    return true;
  } catch (error: any) {
    Swal.fire({
      icon: 'error',
      title: 'Hata',
      text: error.errors[0],
    });
    console.error(error.errors);
    return false;
  }
};
const updateCounties = async () => {
  try {
    const data = await getCounties(customer.value.city_id || 1);
    counties.value = data.data;
  } catch (error) {
    handleApiError(error);
  }
};

const citiesFetch = async () => {
  try {
    const data = await getCities();
    cities.value = data.data;
  } catch (error) {
    handleApiError(error);
  }
}

const groupsFetch = async () => {
  try {
    const data = await getGroups();
    groups.value = data.data;
  } catch (error) {
    handleApiError(error);
  }
}

const customerFetch = async () => {
  try {
    const data = await getCustomerById(customerGetId.value || undefined, true);
    delete data.id;
    delete data.company_id;
    delete data.balance;
    customer.value = data;
  } catch (error) {
    handleApiError(error);
  }
}

onMounted(async () => {
  if (isEditing.value) {
    try {
      const customerData = await getCustomerById(customerId.value || undefined);

      customer.value = customerData;
      //Null verilerini temizle
      await citiesFetch();
      await updateCounties();
    } catch (error) {
      handleApiError(error);
    }
  }
  await citiesFetch();
  await groupsFetch();
});

const goBack = () => {
  router.back();
};
</script>

<style>
.form-g-wrapper {
  padding: 16px;
}

.form-separator {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.button {
  font-size: 14px;
  padding: 7px 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #999593;
  ;

}

.outline-btn {
  border: 2px solid #999593;
  border-radius: 8px;

}

.fill-btn {
  background-color: #5f5755;
  color: #fff;
  border: 2px solid transparent;
}

.save-drop {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.save-toggle {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}


.form-wrapper .form-select {
  padding: 0.65rem;
  border: 1px solid #949494;
}

.form-wrapper .form-select:focus {
  border-color: #949494;
  outline: 0;
  box-shadow: none;
}

.inner-container {
  padding: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}

.badge-bg {
  background: #b9b7b5;
  color: #fff !important;
}
</style>

<style scoped>
.input-textarea {
  margin-top: -6px;
  border: 1px solid #999593;
}

textarea {
  border-bottom-width: 0px;
}

.form-check .checkbox-input {
  margin-left: 0;
}

.form-check-input:focus {
  border-color: #999593;

  box-shadow: none;
}

.input-group {
  border: 1px solid #999593;
  flex-wrap: nowrap;

}

.fist-check {
  border-right: 1px solid #999593;
}


.form-control {
  border-left: 1px solid #949494 !important;
  padding: 4px !important;
}

.form-control:focus {
  border: none !important;
  border-left: 1px solid #949494 !important;
  outline: none !important;
  box-shadow: none !important;
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
  padding: 0.25rem 1.5rem;
  font-size: inherit;
}

.fa-magnifying-glass {
  right: 10px;
}
</style>
@/types/Customer