import api, { ApiResponse } from "./ApiService";
import { CustomerGroups } from "@/types/CustomerGroups";
import { Ref } from "vue";

export async function getGroups(
  params: {
    page?: number;
    pageSize?: number;
    search?: string;
  } = {}
): Promise<ApiResponse<CustomerGroups[]>> {
  const { page = 1, pageSize = 10, search } = params;

  try {
    const response = await api.get("/contacts/groups/list", {
      params: {
        page,
        pageSize,
        search,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Müşteri grupları alınamadı:", error);
    throw error;
  }
}

export async function getGroup(
  id: string
): Promise<ApiResponse<CustomerGroups>> {
  try {
    const response = await api.get(`/contacts/groups/show/${id}`);

    return response.data;
  } catch (error) {
    console.error("Müşteri grubu alınamadı:", error);
    throw error;
  }
}

export async function createGroup(
  data: CustomerGroups
): Promise<ApiResponse<CustomerGroups>> {
  try {
    const response = await api.post("/contacts/groups/create", data);

    return response.data;
  } catch (error) {
    console.error("Müşteri grubu oluşturulamadı:", error);
    throw error;
  }
}

export async function updateGroup(
  id: string,
  data: CustomerGroups
): Promise<ApiResponse<CustomerGroups>> {
  try {
    const response = await api.put(`/contacts/groups/update/${id}`, {
      name: data.name,
      discount: data.discount,
    });

    return response.data;
  } catch (error) {
    console.error("Müşteri grubu güncellenemedi:", error);
    throw error;
  }
}

export async function deleteGroup(
  id: string
): Promise<ApiResponse<CustomerGroups>> {
  try {
    const response = await api.delete(`/contacts/groups/delete/${id}`);

    return response.data;
  } catch (error) {
    console.error("Müşteri grubu silinemedi:", error);
    throw error;
  }
}
