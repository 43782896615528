<template>
    <div class="container">
        <div class="form-g-wrapper">
            <div class="inner-container">
                <div class="d-flex align-items-center gap-5">
                    <div class="input-group flex-grow-1 position-raltive">
                        <input type="text" class="form-control bg-secondary z-1" placeholder="Ara..." v-model="search"
                            @change="fetchModels" />
                        <div class="position-absolute top-50 translate-middle-y end-0 pe-3 z-3">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
                    <button @click="$router.push({ name: 'ModelCreate' })" class="new-btn btn btn-primary">
                        <i class="fa fa-solid fa-plus"></i>
                        Yeni Model Ekle
                    </button>
                </div>

                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-uppercase">Model</th>
                            <th scope="col" class="text-uppercase">Marka</th>
                            <th scope="col" class="text-uppercase text-end">işlem</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="model in models" :key="model.id">
                            <td @click="goToDetail(model.id as number)">{{ model.name }}</td>
                            <td>
                                <RouterLink :to="{ name: 'BrandEdit', params: { id: model.brand?.id } }">{{
                                    model.brand?.name }}
                                </RouterLink>
                            </td>
                            <td class="text-end">
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-secondary btn-active-light-primary show menu-dropdown"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>
                                            <i class="fa-solid fa-ellipsis"></i>
                                        </span></button>
                                    <ul class="dropdown-menu fs-4 " aria-labelledby="speedBoxDropdown" style="">
                                        <li>
                                            <router-link class="dropdown-item"
                                                :to="{ name: 'ModelEdit', params: { id: model.id } }">
                                                <i class="fa-solid fa-edit me-2"></i>
                                                Düzenle
                                            </router-link>
                                        </li>

                                    </ul>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                    <SkeletorTable v-if="models.length == 0" :tdCount="3" />
                </table>

                <PageNavigator :totalPages="totalPages" :currentPage="page" @pageChanged="changePage" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import PageNavigator from '@/components/PageNavigator.vue';
import { handleApiError } from '@/helpers/errorHandling';
import { getModels } from '@/services/ModelsService';
import { Models } from '@/types/Models';
import { ref, Ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import SkeletorTable from '@/components/Skeletors/SkeletorTable.vue';

const router = useRouter();

const models: Ref<Models[]> = ref([]);
const page = ref(1);
const pageSize = ref(10);
const totalPages = ref(0)
const search = ref('')

const fetchModels = async () => {
    try {
        const response = await getModels({ page: page.value, pageSize: pageSize.value, search: search.value });
        models.value = response.data
        totalPages.value = response.totalPages
    } catch (error) {
        handleApiError(error);
    }
}

const changePage = async (newPage: number) => {
    page.value = newPage;
    await fetchModels();
};

const goToDetail = (id: number) => {
    router.push({ name: 'ModelEdit', params: { id: id } })
}

onMounted(async () => {
    await fetchModels()
})


</script>

<style scoped>
.form-control {
    border-radius: 6px !important;
}

.form-control:focus {
    color: inherit;
    background-color: #e8e7e7;
    border-color: #e8e7e7;
    box-shadow: none;
}

.input-group i {
    font-size: 20px;
    color: #999593;
}

.table {
    margin-top: 25px;
}

thead tr th {
    font-size: 1.3rem;
    font-weight: 700;
    padding: 5px 0;
    color: #b2afad;
    padding-left: 10px;
    padding-right: 10px;
}

th input {
    width: unset;
}

.table {
    --bs-table-hover-bg: #f2f1f1;
}

.table> :not(caption)>*>* {
    padding: 10px;
}

.table {
    --bs-table-bg: none;
}

.table>tbody tr {
    background-color: #fdfdfd;
}

tbody tr td {
    font-size: 15px;
}
</style>