import { setLocale } from "yup";

setLocale({
  mixed: {
    required: "${path} zorunlu bir alandır",
    notType: "${path} geçerli bir ${type} olmalıdır",
  },
  string: {
    min: "${path} en az ${min} karakter olmalıdır",
    max: "${path} en fazla ${max} karakter olmalıdır",
    email: "${path} geçerli bir e-posta adresi olmalıdır",
  },
  number: {
    min: "${path} en az ${min} olmalıdır",
    max: "${path} en fazla ${max} olmalıdır",
  },
  
});
