import { Models } from "@/types/Models";
import api, { ApiResponse } from "./ApiService";

export async function getModels(
  params: {
    page?: number;
    pageSize?: number;
    groups?: number;
    hasBalance?: boolean;
    search?: string;
  } = {}
): Promise<ApiResponse<Models[]>> {
  try {
    const response = await api.get("/models/list", {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        search: params.search,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function getModelById(id: string): Promise<ApiResponse<Models>> {
  try {
    const response = await api.get(`models/show/${id}`);
    return response.data;
  } catch (error) {
    console.log("Api Error", error);
    throw error;
  }
}

export async function createModel(model: Models) {
  try {
    const response = await api.post("/models/create", {
      ...model,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateModel(model: Models, id: string) {
  try {
    const response = await api.put(`/models/update/${id}`, {
      name: model.name,
      brand_id: model.brand_id,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteModel(id: any) {
  try {
    const response = await api.delete(`/models/delete/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
