import store from "../store";

/**
 *
 * @param value - number
 * @param currency - TRY, USD, EUR
 * @param currency.locale - en-US, tr-TR
 * @returns
 */

interface Currency {
  locale: string;
  code: string;
}

export const format = (value: number | undefined | null, currency?: Currency): string => {
  const defaultCurrency = store.getters["getUser"].currency;
  let formattedValue: string;

  if (!value) {
    return value?.toString() || "";
  }

  if (currency) {
    formattedValue = new Intl.NumberFormat(currency.locale, {
      style: "currency",
      currency: currency.code,
    }).format(value);
  } else if (defaultCurrency) {
    formattedValue = new Intl.NumberFormat(defaultCurrency.locale, {
      style: "currency",
      currency: defaultCurrency.code,
    }).format(value);
  } else {
    formattedValue = value.toLocaleString(); // Default number formatting if no currency is provided
  }

  // Remove currency symbol
  const parts = new Intl.NumberFormat(
    currency?.locale || defaultCurrency?.locale || "en-US",
    {
      style: "currency",
      currency: currency?.code || defaultCurrency?.code || "USD",
    }
  ).formatToParts(value);

  const currencySymbol = parts.find((part) => part.type === "currency")?.value;
  const amountWithoutSymbol = formattedValue
    .replace(currencySymbol || "", "")
    .trim();

  return amountWithoutSymbol;
};
