import { Safes } from "@/types/Safes";
import api, { ApiResponse } from "./ApiService";

export async function getSafes(
  params: {
    page?: number;
    pageSize?: number;
    groups?: number;
    hasBalance?: boolean;
    search?: string;
  } = {}
): Promise<ApiResponse<Safes[]>> {
  try {
    const response = await api.get("/accounts/list", {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        search: params.search,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function getSafeById(
  id: string
): Promise<ApiResponse<Safes>> {
  try {
    const response = await api.get(`/accounts/show/${id}`);
    return response.data;
  } catch (error) {
    console.log("Api Error", error);
    throw error;
  }
}

export async function createSafe(safe: Safes) {
  try {
    const response = await api.post("/accounts/create", {
      ...safe,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateSafe(safe: Safes, id: string) {
  try {
    const response = await api.put(`/accounts/update/${id}`, {
      ...safe,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteSafe(id: any) {
  try {
    const response = await api.delete(`/accounts/delete/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
