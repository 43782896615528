import { createStore, Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import authModule from "./modules/auth";
import customerModule from "./modules/customer"; 
import billModule from "./modules/bill";
import storeModule from "./modules/store";
import bankModule from './modules/vaultBank'
import { AuthState } from "@/types/UserTypes";

export interface RootState {
  auth: AuthState;
  customer: typeof customerModule.state;
  bill: typeof billModule.state;
  store: typeof storeModule.state;
  bank:typeof bankModule.state;
}

const store: Store<RootState> = createStore<RootState>({
  modules: {
    auth: authModule,
    customer: customerModule, 
    bill:billModule,
    store:storeModule,
    bank:bankModule,
  },
  plugins: [createPersistedState()],
});

export default store;
