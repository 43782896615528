<template>
  <div class="container" v-if="!addProduct">
    <div class="form-g-wrapper">
      <div class="inner-container">
        <div class="row align-items-center gap-2 mt-2">
          <div class="col-12 col-md d-flex align-items-center gap-1">
            <div class="dropdown btn-drop">
              <button class="btn btn-sm btn-flex btn-secondary fw-bold d-flex align-items-center gap-2" type="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-filter"></i>
                Filtrele
              </button>
              <ul class="dropdown-menu">
                <li><span>Tümü</span></li>
              </ul>
            </div>

            <div class="input-group flex-grow-1 position-relative">
              <input type="text" class="form-control bg-secondary z-1 fs-4" placeholder="Ara..." v-model="search"
                @change="fetchProducts" />
              <div class="position-absolute top-50 translate-middle-y end-0 pe-3 z-3">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-auto mt-2 mt-md-0">
            <RouterLink :to="{ name: 'ProductCreate' }" class="new-btn btn btn-primary w-100">
              <i class="fa-solid fa-plus"></i>
              Yeni Ürün Oluştur
            </RouterLink>
          </div>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="user-col"> <input class="form-check-input checkbox-input ms-3" type="checkbox"
                  value=""></th>
              <th scope="col" class="text-uppercase col-4">
                Adı
                <i class="fa-solid fa-arrow-down-short-wide"></i>
              </th>
              <th scope="col" class="text-uppercase text-end">stok miktarı</th>
              <th scope="col" class="text-uppercase text-end">alış (vergiler hariç)</th>
              <th scope="col" class="text-uppercase text-end">satış (vergiler hariç)</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody v-if="products">
            <tr v-for="product in products" :key="product.id">
              <th scope="row">
                <div class="d-flex align-items-center justify-content-center table-icon-box">
                  <i class="fa-solid fa-file-invoice"></i>
                </div>
              </th>
              <td>
                <div class="d-flex flex-column gap-1">
                  <div class="user-name">{{ product.name }}</div>
                  <div class="d-flex gap-2" v-if="product.warranty_date != null">
                    <div class="badge d-flex gap-2 align-items-center">
                      <span class="dot"></span>
                      <small>İkinci El Ürün</small>
                    </div>
                    <div class="badge">
                      <small v-if="product.warranty_date">Garanti Bitiş Tarihi: {{ dateHelper(product.warranty_date)
                        }}</small>
                    </div>
                    <div class="badge">
                      <small v-if="product.expiration_date">Son Kullanma Tarihi: {{ dateHelper(product.expiration_date)
                        }}</small>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-end" v-if="product.stock > 10">{{ product.stock + ' ' + product.unit?.name }}</td>

              <td class="text-end text-primary" v-else-if="product.stock <= 10 && product.stock > 5">{{ product.stock +
                ' ' +
                product.unit?.name }}</td>
              <td class="text-end text-danger" v-else-if="product.stock < 5">{{ product.stock + ' ' + product.unit?.name
                }}
              </td>
              <td class="text-end text-danger" v-else>{{ product.stock + ' ' + product.unit?.name }}
              </td>
              <td class="text-end">{{ format(product.purchase_price) }} {{ product.currency?.symbol }}</td>
              <td class="text-end"> {{ format(product.sale_price) + ' ' + product.currency?.symbol }} </td>
              <td class="text-end">
                <div class="dropdown">
                  <button class="btn btn-sm btn-secondary btn-active-light-primary show menu-dropdown" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <span>
                      <i class="fa-solid fa-ellipsis"></i>
                    </span></button>
                  <ul class="dropdown-menu fs-4 " aria-labelledby="speedBoxDropdown" style="">
                    <li>
                      <router-link class="dropdown-item" :to="{ name: 'ProductDetail', params: { id: product.id } }">
                        <i class="fa-solid fa-eye me-2"></i>
                        Görüntüle
                      </router-link>
                      <router-link class="dropdown-item" :to="{ name: 'ProductEdit', params: { id: product.id } }">
                        <i class="fa-solid fa-edit me-2"></i>
                        Düzenle
                      </router-link>
                    </li>

                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center">Kayıt bulunamadı</td>
            </tr>
          </tbody>
        </table>

        <PageNavigator :totalPages="totalPages" :currentPage="page" @pageChanged="changePage" />
      </div>
    </div>
  </div>

  <!-- İkinci el ürün -->
  <ProductAddModal v-if="addProduct" @close="closeProductModal" />
</template>

<script setup lang="ts">
import PageNavigator from '@/components/PageNavigator.vue'
import ProductAddModal from '@/components/ProductAddModal.vue';
import { ref, Ref, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { handleApiError } from '@/helpers/errorHandling';
import { getProducts } from '@/services/ProductsServices';
import { Products } from '@/types/ProductsTypes';
import { dateHelper } from '@/helpers/generalHelpers';
import { format } from '@/helpers/priceHelper';

const router = useRouter();

const products: Ref<Products[]> = ref([]);
const page = ref(1);
const pageSize = ref(10);
const totalPages = ref(0)
const search = ref('')
const addProduct = ref(false)

const closeProductModal = () => {
  addProduct.value = false
  fetchProducts()
}

const fetchProducts = async () => {
  try {
    const response = await getProducts({ page: page.value, pageSize: pageSize.value, search: search.value });
    products.value = response.data
    totalPages.value = response.totalPages
  } catch (error) {
    handleApiError(error);
  }
}

const changePage = async (newPage: number) => {
  page.value = newPage;
  await fetchProducts();
};

const goToDetail = (id: number) => {
  router.push({ name: 'ProductDetail', params: { id: id } })
}

onMounted(async () => {
  await fetchProducts()
})

</script>

<style scoped>
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #f2c94c;
}

.badge {
  background-color: #f7f7f6;
  border-radius: 8px;
}

small {
  color: #999593;
}

.btn-group {
  height: 40px;
  white-space: nowrap;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
 border-radius: 6px;
}

.input-group i {
  font-size: 20px;
  color: #999593;
}

.filter-btn {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  padding: 1.5px 30px 1.5px 15px;
  outline: 0;
  background-color: #d2d0cf;
  line-height: 40px;
  font-size: 1.3rem;
  font-weight: 600;
  color: #999593;
  transition: 0.5s;
}


.user-name::first-letter {
  text-transform: uppercase;
}

.user-info {
  font-size: 13px;
}

.user-col {
  width: 70px;
}

</style>