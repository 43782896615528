<template>
  <div class="col-lg-3 right-aside-wrap">
    <aside class="right-aside">
      <input type="text" placeholder="Aratacağınız kelimeleri giriniz." />
      <div class="exchange-box">
        <span class="amount">Miktar</span>
        <div class="d-flex align-items-center justify-content-center mb-4">
          <span class="currency-img"></span>
          <select id="currency">
            <option value="">SGD</option>
            <option value="">USD</option>
            <option value="">EUR</option>
          </select>
          <input class="currency-input" type="number" placeholder="1000.00" />
        </div>
      </div>

      <span class="exchange-icon d-flex align-items-center justify-content-center">
        <img src="@/assets/images/icons/exchange.svg" alt="exchange-icon" />
      </span>

      <div class="exchange-box converted">
        <span class="amount">Dönüştürülen Tutar</span>
        <div class="d-flex align-items-center justify-content-center mb-4">
          <span class="currency-img"></span>
          <select id="currency">
            <option value="">SGD</option>
            <option value="">USD</option>
            <option value="">EUR</option>
          </select>
          <input class="currency-input" type="number" placeholder="736.70" />
        </div>
      </div>

      <div class="events-box">
        <div class="events-button d-flex align-items-center">
          <img src="@/assets/images/icons/chexk-square.svg" alt="chexk" />
          <span class="current-time">19.09.2023 (Bugün)</span>
        </div>
        <div class="events-info">
          <div class="info">
            <span class="event">Oturum Açıldı</span>
            <span class="time">18 Eylül, 2023, 4:23</span>
          </div>
        </div>
      </div>
      <div class="past-event-box">
        <div class="past-events-info position-relative">
          <span class="past-time" style="margin-top: -10px; margin-left: 52px; margin-bottom: 10px">Dün</span>
          <div class="info">
            <span class="event">Oturum Açıldı</span>
            <span class="time">18 Eylül, 2023, 4:23</span>
          </div>
        </div>
        <div
          class="events-button past-event-button past-event position-relative d-flex align-items-center justify-content-center">
          <span class="current-time">Geçmiş İşlemleri Göster</span>
        </div>
      </div>
    </aside>
  </div>
</template>
<script setup lang="ts">
import { defineComponent } from 'vue';
defineComponent({
  name:'RightSideComponent'
})
</script>