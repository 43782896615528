import api from "./ApiService";
import { Ref } from "vue";
import { ApiResponse } from "./ApiService";
import { Brands } from "@/types/Brands";

export async function getBrands(
  params: {
    page?: number;
    pageSize?: number;
    groups?: number;
    hasBalance?: boolean;
    search?: string;
  } = {}
): Promise<ApiResponse<Brands[]>> {
  try {
    const response = await api.get("/brands/list", {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        search: params.search,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function getBrandById(id: string): Promise<ApiResponse<Brands>> {
  try {
    const response = await api.get(`brands/show/${id}`);
    return response.data;
  } catch (error) {
    console.log("Api Error", error);
    throw error;
  }
}

export async function createBrand(brand: Brands) {
  try {
    const response = await api.post("/brands/create", {
      ...brand,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateBrand(brand: Brands, id: string) {
  try {
    const response = await api.put(`/brands/update/${id}`, {
      name: brand.name,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteBrand(id: any) {
  try {
    const response = await api.delete(`/brands/delete/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
