import PurchaseInvoiceView from "@/views/PurchaseInvoice/PurchaseInvoiceView.vue";

export const PurchaseInvoiceRoutes = [
  {
    path: "/purchase-invoice",
    name: "purchaseInvoiceList",
    component: PurchaseInvoiceView,
  },
  {
    path: "/purchase-invoice/create",
    name: "saleInvoiceCreate",
    component: () => import("@/views/PurchaseInvoice/PurchaseInvoiceForm.vue"),
  },
  {
    path: "/purchase-invoice/edit/:id",
    name: "saleInvoiceEdit",
    component: PurchaseInvoiceView,
  },
];
