import { createRouter, createWebHistory } from "vue-router";
import store from "@/store"; // store'u doğrudan import ettik

// Modules
import { AuthRoutes } from "./AuthRoutes";
import { CustomersRoutes } from "./CustomersRoutes";
import { SaleInvoiceRoutes } from "./SaleInvoiceRoutes";
import { BrandsRoutes } from "./BrandsRoutes";
import { ModelsRoutes } from "./ModelsRoutes";
import { WarehousesRoutes } from "./WarehousesRoutes";
import { SafesRoutes } from "./SafesRoutes";
import { ProductsRoutes } from "./ProductsRoutes";
import { ProductCategoriesRoutes } from "./ProductCategories";
import { CustomerGroupsRoutes } from "./CustomerGroupsRoutes";
import { PurchaseInvoiceRoutes } from "./PurchaseInvoiceRoutes";
import { ShelvesRoutes } from "./ShelvesRoutes";
import { ReturnInvoiceRoutes } from "./ReturnInvoiceRoutes";
import { DocumentRoutes } from "./DocumentRoutes";
import { ReportsRoutes } from "./ReportsRoutes";
// Layouts
import MainLayout from "@/layouts/MainLayout.vue";

// Views
import HomeView from "@/views/HomeView.vue";

const routes = [
  {
    path: "/",
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "Home",
        component: HomeView,
      },
      {
        path: "/settings/profile",
        name: "Profile",
        component: () => import("@/views/Settings/ProfileView.vue"),
      },
      {
        path: "/settings/change-password",
        name: "ChangePassword",
        component: () => import("@/views/Settings/PasswordView.vue"),
      },
      ...CustomersRoutes,
      ...SaleInvoiceRoutes,
      ...PurchaseInvoiceRoutes,
      ...BrandsRoutes,
      ...ModelsRoutes,
      ...WarehousesRoutes,
      ...SafesRoutes,
      ...ProductsRoutes,
      ...CustomerGroupsRoutes,
      ...ReturnInvoiceRoutes,
      ...ProductCategoriesRoutes,
      ...ShelvesRoutes,
      ...DocumentRoutes,
      ...ReportsRoutes
    ],
  },
  // External modules
  ...AuthRoutes,
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["getToken"]) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
