<template>
  <Transition name="slide-fade">
    <router-view />
  </Transition>
</template>

<style>
@import url('bootstrap/dist/css/bootstrap.min.css');
@import url('vue-skeletor/dist/vue-skeletor.css');

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
