<template>

    <div class="container">

        <div class="row d-flex justify-content-center">
            <div class="col-sm-3">
                <div class="card bg-success text-white" v-if="!loading.first">
                    <div class="card-body">
                        <h2 class="card-title">Alacak</h2>

                        <p class="card-text">{{ format(credit) }} ₺</p>
                    </div>
                </div>
                <Skeletor width="390" height="91" class="card bg-success" v-else></Skeletor>
            </div>
            <div class="col-sm-3">
                <div class="card bg-danger text-white" v-if="!loading.first">
                    <div class="card-body">
                        <h2 class="card-title">Borç</h2>
                        <p class="card-text">{{ format(debt) }} ₺</p>
                    </div>
                </div>
                <Skeletor width="390" height="91" class="card bg-danger" v-else></Skeletor>
            </div>
            <div class="col-sm-3">
                <div class="card bg-primary text-white" v-if="!loading.first">
                    <div class="card-body">
                        <h2 class="card-title">Bakiye</h2>
                        <p class="card-text">{{ format(credit - debt) }} ₺</p>
                    </div>
                </div>
                <Skeletor width="390" height="91" class="card bg-primary" v-else></Skeletor>
            </div>
        </div>
        <div class="form-g-wrapper">
            <div class="inner-container">

                <div class="row align-items-center gap-2 mt-2">
                    <div class="col-12 col-md d-flex align-items-center gap-1">
                        <div class="dropdown btn-drop">
                            <button class="btn btn-sm btn-flex btn-secondary fw-bold d-flex align-items-center gap-2"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa-solid fa-filter"></i>
                                Filtrele
                            </button>
                            <ul class="dropdown-menu">
                                <li><span @click="hasBalance = false, hasDebt = false">Tümü</span></li>
                                <li><span @click="hasBalance = !hasBalance">Alacaklılar</span></li>
                                <li><span @click="hasDebt = !hasDebt">Borçlular</span></li>
                            </ul>
                        </div>

                        <div class="input-group flex-grow-1 position-relative">
                            <input type="text" class="form-control bg-secondary z-1 fs-4" placeholder="Ara..."
                                v-model="search" />
                            <div class="position-absolute top-50 translate-middle-y end-0 pe-3 z-3">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-auto mt-2 mt-md-0">
                        <RouterLink :to="{ name: 'CustomerCreate' }" class="new-btn btn btn-primary w-100">
                            <i class="fa-solid fa-plus"></i>
                            Yeni Müşteri Oluştur
                        </RouterLink>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex gap">
                            <div v-if="hasBalance" class="badge bg-warning" @click="hasBalance = !hasBalance">
                                Alacaklılar
                                <i class="fa-solid fa-xmark"></i>
                            </div>
                            <div v-if="hasDebt" class="badge bg-danger" @click="hasDebt = !hasDebt">
                                Borçlular
                                <i class="fa-solid fa-xmark"></i>
                            </div>
                            <div v-if="search" class="badge bg-primary" @click="search = null">
                                Arama: {{ search }}
                                <i class="fa-solid fa-xmark"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <table class="table gy-5">
                    <thead>
                        <tr>
                            <th></th>
                            <th scope="col">ÜNVANI</th>
                            <th scope="col">VKN/TCKN</th>
                            <th scope="col" class="text-end">BAKİYE</th>
                            <th scope="col" class="text-end">İŞLEMLER</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loading.customers">
                        <tr v-for="customer in customers" :key="customer.id">
                            <td>
                                <div class="d-flex align-items-center justify-content-center icon-box">
                                    <i class="fa-solid fa-person"></i>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column gap-1">
                                    <div class="user-name">{{ customer.title }}</div>
                                    <div class="user-info d-flex gap-2">
                                        <span class="e-mail">{{ customer.email }}</span>
                                        <span>-</span>
                                        <span class="phone">{{ customer.phone }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>{{ customer.tax_number }}</div>
                            </td>
                            <td class="text-end fw-bold">
                                {{ format(customer.balance) }}₺
                                <span class="text-danger fw-bold fs-5" v-if="customer.balance  > 1">Ödenecek</span>
                                <span class="text-primary fw-bold fs-5" v-else-if="customer.balance < 0">Alacak</span>
                            </td>
                        

                            <td class="text-end">
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-secondary btn-active-light-primary show menu-dropdown"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>
                                            <i class="fa-solid fa-ellipsis"></i>
                                        </span></button>
                                    <ul class="dropdown-menu fs-4 " aria-labelledby="speedBoxDropdown" style="">
                                        <li>
                                            <router-link class="dropdown-item"
                                                :to="{ name: 'CustomerDetail', params: { id: customer.id } }">
                                                <i class="fa-solid fa-eye me-2"></i>
                                                Görüntüle
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link class="dropdown-item "
                                                :to="{ name: 'CustomerEdit', params: { id: customer.id } }">
                                                <i class="fa-solid fa-edit me-2 "></i>
                                                Düzenle
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>

                            </td>

                        </tr>
                    </tbody>
                    <tbody v-else-if="customers.length == 1">
                        <tr>
                            <td colspan="5" class="text-center">Kayıt bulunamadı</td>
                        </tr>
                    </tbody>
                    <CustomerSkeletor v-else></CustomerSkeletor>
                </table>

                <PageNavigator :totalPages="totalPages" :current-page="page" @page-changed="changePage" />
                <CustomerModal />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, Ref, watch, onMounted } from 'vue';
import PageNavigator from '@/components/PageNavigator.vue';
import { handleApiError } from '@/helpers/errorHandling';
import { getCustomers } from '@/services/CustomerService';
import { Customer } from '@/types/Customer';
import { useRouter } from 'vue-router';
import { Skeletor } from 'vue-skeletor';
import { reactive } from 'vue';
import { format } from '@/helpers/priceHelper';
import CustomerSkeletor from '@/components/Skeletors/CustomerSkeletor.vue';

const router = useRouter();

const customers: Ref<Customer[]> = ref([]);
const page = ref(1);
const pageSize = ref(10);
const totalPages = ref(0)
const search = ref()
const debt = ref(0)
const credit = ref(0)
const balance = ref(0)
const hasBalance = ref(false)
const hasDebt = ref(false)

const loading = reactive({
    customers: false,
    first: true
})

//hasBalance ve hasDebt değerlerine göre müşterileri filtreleme
//Watch 
watch([hasBalance, hasDebt, search], async () => {
    //Hasbalance veya hasDebt ikisinden biri sadece true ise diğerini false yap
    if (hasBalance.value && hasDebt.value) {
        hasBalance.value = false;
    }
    await fetchCustomers();
})

const fetchCustomers = async () => {
    loading.customers = true;
    try {
        const response = await getCustomers({ page: page.value, pageSize: pageSize.value, search: search.value, hasBalance: hasBalance.value, hasDebt: hasDebt.value });
        customers.value = response.data;
        totalPages.value = response.totalPages;
        debt.value = response.debt;
        credit.value = response.credit;

        (loading.first) ? loading.first = false : null;
        loading.customers = false;
    } catch (error) {
        handleApiError(error);
    }
}

const changePage = async (newPage: number) => {
    page.value = newPage;
    await fetchCustomers();
}

const goDetail = (id: number) => {
    router.push({ name: 'CustomerDetail', params: { id: id } })
}

const goToCreateCustomer = () => {
    router.push({ name: 'CustomerCreate' })
}

onMounted(async () => {
    await fetchCustomers()
})

</script>

<style scoped>
.icon-box {
    width: 40px;
    height: 40px;
    line-height: 39px;
    font-size: 2.4rem;
    background-color: #dedddc;
    border-radius: 50%;
    text-align: center;
    color: #a39f9d;
}


.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
 border-radius: 6px;
}

.input-group i {
  font-size: 20px;
  color: #999593;
}
</style>