<template>
    <CustomerForm :is-editing="true" :customerId="customerId" />
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router'
import CustomerForm from '@/components/CustomerForm.vue';

const route = useRoute()
const customerId = route.params.id;
</script>
