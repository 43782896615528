import { ActionContext, Module } from 'vuex';
import { RootState } from '../index';
import { boolean } from 'yup';


interface BillData {
  billDescription: string;
  customerName: string;
  collectionStatus:boolean;
  dateOfIssue:Date;
  date: Date;
  expiryDate: Date;
  serialNumber:number;
  sequenceNumber:number;
  orderNumber:number;
  stockTracking: boolean;
  serviceProduct: string;
  amount:number;
  unit:number;
  unitPrice:number;
  tax:number;
  total: number;
}

// interface BillState {
//   billData: BillData | null;
// }
interface State {
  billData: BillData | null;
  bills: BillData[];
}

// const state = (): BillState => ({
//   billData: null,
// });

const state = (): State => ({
  billData: {
    billDescription: '',
  customerName: '',
  collectionStatus:false,
  dateOfIssue: new Date(),
  date: new Date(),
  expiryDate: new Date(),
  serialNumber:0,
  sequenceNumber:0,
  orderNumber:0,
  stockTracking: false,
  serviceProduct: '',
  amount: 0,
  unit: 0,
  unitPrice: 0,
  tax: 0,
  total: 0
  },
  bills:[],
});

const mutations = {
  setBillData(state: State, data: BillData) {
    state.billData = data;
  },
  addBill(state: State, newStoreData: BillData) {
    state.bills.push(newStoreData);
  },
};


const actions = {
  updateBillData({ commit }: ActionContext<State, RootState>, data: BillData) {
    commit('setBillData', data);
  },
  addNewBill({ commit }: ActionContext<State, RootState>, newStoreData: BillData) {
    commit('addBill', newStoreData);
  },
};


const getters = {
  getBillData: (state: State) => state.billData,
};


const billModule: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default billModule;