import api, { ApiResponse } from "./ApiService";
import { Shelves } from "@/types/Shelves";

export async function getShelves(
  params: {
    page?: number;
    pageSize?: number;
    groups?: number;
    hasBalance?: boolean;
    search?: string;
  } = {}
): Promise<ApiResponse<Shelves[]>> {
  try {
    const response = await api.get("/shelves/list", {
      params: {
        page: params.page,
        pageSize: params.pageSize || 10,
        search: params.search,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function getShelvesById(
  id: string
): Promise<ApiResponse<Shelves>> {
  try {
    const response = await api.get(`shelves/show/${id}`);
    return response.data;
  } catch (error) {
    console.log("Api Error", error);
    throw error;
  }
}

export async function getShelveByWarehouseId(
  id: number
): Promise<ApiResponse<Shelves[]>> {
  try {
    const response = await api.get(`shelves/list`, {
      params: {
        warehouseId: id,
        page: 1,
        pageSize: 1000,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Api Error", error);
    throw error;
  }
}

export async function createShelve(shelve: Shelves) {
  try {
    const response = await api.post("/shelves/create", {
      ...shelve,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateShelve(shelve: Shelves, id: string) {
  try {
    const response = await api.put(`/shelves/update/${id}`, {
      name: shelve.name,
      warehouse_id: shelve.warehouse_id,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function deleteShelve(id: any) {
  try {
    const response = await api.delete(`/shelves/delete/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
