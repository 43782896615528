import SafesView from "@/views/Safes/SafesView.vue";

export const SafesRoutes = [
  {
    path: "/safes",
    name: "SafesList",
    component: SafesView,
  },
  {
    path: "/safes/details/:id",
    name: "SafeDetail",
    component: () => import("@/views/Safes/SafesDetailView.vue"),
  },
  {
    path: "/safes/create",
    name: "SafeCreate",
    component: () => import("@/views/Safes/SafesFormView.vue"),
  },
  {
    path: "/safes/edit/:id",
    name: "SafeEdit",
    component: () => import("@/views/Safes/SafesFormView.vue"),
  },
];
