import BrandsView from "@/views/Brands/BrandsView.vue";

export const BrandsRoutes = [
  {
    path: "/brands",
    name: "BrandsList",
    component: BrandsView,
  },
  {
    path: "/brands/create",
    name: "BrandCreate",
    component: () => import("@/views/Brands/BrandsForm.vue"),
  },
  {
    path: "/brands/edit/:id",
    name: "BrandEdit",
    component: () => import("@/views/Brands/BrandsForm.vue"),
  },
];
