import CustomerView from "@/views/Customers/CustomerView.vue";
import CustomerCreateView from "../views/Customers/CustomerCreateView.vue";
import CustomerEditView from "@/views/Customers/CustomerEditView.vue";
export const CustomersRoutes = [
  {
    path: "/customers",
    name: "CustomersList",
    component: CustomerView,
  },
  {
    path: "/customers/create",
    name: "CustomerCreate",
    component: CustomerCreateView,
  },
  {
    path: "/customers/edit/:id",
    name: "CustomerEdit",
    component: CustomerEditView,
  },
  {
    path: "/customers/detail/:id",
    name: "CustomerDetail",
    component: () => import("@/views/Customers/CustomerDetailView.vue"),
  },
];
