/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
// Bootstrap
import "bootstrap";

//Popper.js
import "vue3-popper";

//Skeleton CSS
import { Skeletor } from "vue-skeletor";

//Datepicker
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import "./locales/yupLocale";
import '@bhplugin/vue3-datatable/dist/style.css'
// Global Variables
const API_URL = "http://localhost:3000/";

//FIXME: i18n global tanımlamasını sağla
const i18n = createI18n({
  locale: "tr",
  messages: require("@/locales/tr.json"),
});

//Helpers
import { format } from "@/helpers/priceHelper";

// Create Vue App
const app = createApp(App);

// Use Plugins
app.use(store);
app.use(router);
app.use(i18n);
app.component(Skeletor.name, Skeletor)
app.component('VueDatePicker', VueDatePicker);

// Set Global Variable
app.config.globalProperties.$apiUrl = API_URL;
app.config.globalProperties.$format = format;

// Mount the app
app.mount("#app");
