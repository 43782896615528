<template>
    <div class="container">
        <div class="form-g-wrapper">
            <div class="inner-container">
                <div class="row align-items-center gap-2 mt-2">
                    <div class="col-12 col-md d-flex align-items-center gap-1">
                        <div class="dropdown btn-drop">
                            <button class="btn btn-sm btn-flex btn-secondary fw-bold d-flex align-items-center gap-2"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa-solid fa-filter"></i>
                                Filtrele
                            </button>
                            <ul class="dropdown-menu">
                            
                            </ul>
                        </div>

                        <div class="input-group flex-grow-1 position-relative">
                            <input type="text" class="form-control bg-secondary z-1 fs-4" placeholder="Ara..."
                                v-model="search" />
                            <div class="position-absolute top-50 translate-middle-y end-0 pe-3 z-3">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-auto mt-2 mt-md-0">
                        <RouterLink :to="{ name: 'createReturnInvoice' }" class="new-btn btn btn-primary w-100">
                            <i class="fa-solid fa-plus"></i>
                            Yeni İade Faturası Oluştur
                        </RouterLink>
                    </div>
                </div>

                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="user-col"> <input class="form-check-input checkbox-input ms-3"
                                    type="checkbox" value=""></th>
                            <th scope="col" class="text-uppercase">fatura açıklaması</th>
                            <th scope="col" class="text-uppercase">fatura no</th>
                            <th scope="col" class="text-uppercase">düzenleme tarihi</th>
                            <th scope="col" class="text-uppercase">vade tarihi</th>
                            <th scope="col" class="text-uppercase text-end">
                                kalan meblağ
                                <i class="fa-solid fa-arrow-down-short-wide"></i>
                            </th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="invoice in invoices" :key="invoice.id">
                            <th scope="row">
                                <div class="d-flex align-items-center justify-content-center table-icon-box">
                                    <i class="fa-solid fa-file-invoice"></i>
                                </div>
                            </th>
                            <td>
                                <div class="d-flex flex-column gap-1">

                                    <div class="user-name">{{ invoice.description || '-' }}</div>
                                    <small>{{ invoice.contact?.title || '-' }}</small>
                                </div>
                            </td>
                            <td>{{ 'Belirtilmemiş' }}</td>
                            <td>
                                <div class="d-flex flex-column gap-1">
                                    <div class="date">{{ dateHelper(invoice.issue_date) ?? '-' }}</div>
                                    <div class="user-info d-flex gap-2">
                                        <small class="e-mail">e-Arşiv Fatura</small>
                                        <small class="badge d-flex align-items-center gap-1 text-uppercase">
                                            <span class="dot"></span> Resmileştirildi
                                        </small>
                                    </div>
                                </div>
                            </td>
                            <td>{{ dateHelper(invoice.due_date) ?? '-' }}</td>
                            <td class="text-end">
                                <div class="d-flex flex-column gap-1">
                                    <div class="user-name" v-if="invoice.remaining == 0">Tahsil edildi</div>
                                    <div class="user-name" v-if="invoice.remaining != 0">
                                        {{ invoice.remaining }}
                                        {{ invoice.currency?.symbol }}
                                    </div>
                                    <small>Genel Toplam {{ invoice.total ?? '-' }} {{ invoice.currency?.symbol
                                        }}</small>
                                </div>
                            </td>
                            <td>
                                <div class="dropdown">
                                    <div class="dropdown">
                                        <button class="button ms-auto outline-btn d-flex justify-content-end"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            İşlemler
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><router-link class="dropdown-item"
                                                :to="{ name: 'documentDetail', params: { id: invoice.id } }">Görüntüle</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
                
                <PageNavigator :totalPages="totalPages" :currentPage="currentPage" @pageChanged="changePage" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import PageNavigator from "@/components/PageNavigator.vue";
import { handleApiError } from "@/helpers/errorHandling";
import { ref, onMounted } from "vue";
import { dateHelper } from "@/helpers/generalHelpers";
import { Documents } from "@/types/Documents";
import { getReturnInvoices } from "@/services/ReturnInvoiceService";

const totalPages = ref(10);
const currentPage = ref(1);
const search = ref("");
const invoices = ref<Documents[]>([]);
const changePage = async (page: number) => {
    currentPage.value = page;
};

const fetchInvoices = async () => {
    try {
        const response = await getReturnInvoices({
            page: currentPage.value,
        });
        invoices.value = response.data;
        totalPages.value = response.totalPages;

    } catch (error) {
        handleApiError(error);
    }
};

onMounted(async () => {
    await fetchInvoices();
});
</script>
<style></style>

<style scoped>
.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f2c94c;
}

.badge {
    background-color: #f7f7f6;
    border-radius: 8px;
}

small {
    color: #999593;
}

.btn-group {
    height: 40px;
    white-space: nowrap;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-radius: 6px;
}

.input-group i {
    font-size: 20px;
    color: #999593;
}

.filter-btn {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    padding: 1.5px 30px 1.5px 15px;
    outline: 0;
    background-color: #d2d0cf;
    line-height: 40px;
    font-size: 1.3rem;
    font-weight: 600;
    color: #999593;
    transition: 0.5s;
}

.dropdown-toggle::after {
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}

.user-name::first-letter {
    text-transform: uppercase;
}

.user-info {
    font-size: 13px;
}

.user-col {
    width: 70px;
}

.dropdown-menu {
    min-width: 260px;
    width: 100%;
}

.dropdown-item {
    color: #999593;
    font-weight: 700;
    font-size: 13px;
    padding: 5px 15px;
}
</style>