<template>
    <div class="container">
        <div class="form-g-wrapper">
            <div class="inner-container">
                <div class="d-flex align-items-center gap-5">

                    <div class="input-group flex-grow-1 position-raltive">
                        <input type="text" class="form-control bg-secondary z-1" placeholder="Ara..." v-model="search"
                            @change="fetchWarehouses" />
                        <div class="position-absolute top-50 translate-middle-y end-0 pe-3 z-3">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>

                    <button @click="router.push({ name: 'WarehouseCreate' })"
                        class="new-btn btn btn-primary text-uppercase">
                        <i class="fa-solid fa-plus"></i>
                        Yeni Depo Ekle
                    </button>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width:70px;"></th>
                            <th scope="col" class="text-uppercase">
                                depo
                                <i class="fa-solid fa-arrow-down-a-z"></i>
                            </th>
                            <th scope="col" class="text-uppercase">adres</th>
                            <th scope="col" class="text-uppercase text-end">işlem</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="warehouse in warehouses" :key="warehouse.id">
                            <th scope="row">
                                <div class="d-flex align-items-center justify-content-center table-icon-box">
                                    <i class="fa-solid fa-store"></i>
                                </div>
                            </th>
                            <td>
                                <div class="d-flex flex-column gap-1"
                                    @click="router.push({ name: 'WarehouseDetail', params: { id: warehouse.id } })">
                                    <div class="">{{ warehouse.name }}</div>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column gap-1">
                                    <div class="">
                                        {{ warehouse.address }}
                                        {{ warehouse.county?.name }}
                                        {{ warehouse.city?.name }}
                                    </div>
                                </div>
                            </td>
                            <td class="text-end">
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-secondary btn-active-light-primary show menu-dropdown"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>
                                            <i class="fa-solid fa-ellipsis"></i>
                                        </span></button>
                                    <ul class="dropdown-menu fs-4 " aria-labelledby="speedBoxDropdown" style="">
                                        <li>
                                            <router-link class="dropdown-item"
                                                :to="{ name: 'WarehouseDetail', params: { id: warehouse.id } }">
                                                <i class="fa-solid fa-eye me-2"></i>
                                                Görüntüle
                                            </router-link>
                                            <router-link class="dropdown-item"
                                                :to="{ name: 'WarehouseEdit', params: { id: warehouse.id } }">
                                                <i class="fa-solid fa-edit me-2"></i>
                                                Düzenle
                                            </router-link>
                                        </li>

                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <PageNavigator :totalPages="totalPages" :currentPage="page" @pageChanged="changePage" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import PageNavigator from '@/components/PageNavigator.vue';
import { ref, Ref, onMounted } from 'vue';
import { getWarehouses } from '@/services/WarehousesService';
import { Warehouses } from '@/types/Warehouses';
import { handleApiError } from '@/helpers/errorHandling';
import { useRouter } from 'vue-router';

const router = useRouter();
const warehouses: Ref<Warehouses[]> = ref([]);
const page = ref(1);
const pageSize = ref(10);
const totalPages = ref(1)
const search = ref('')

const fetchWarehouses = async () => {
    try {
        const data = await getWarehouses({ page: page.value, pageSize: pageSize.value, search: search.value });
        warehouses.value = data.data;
        totalPages.value = data.totalPages;
    } catch (error) {
        handleApiError(error);
    }
};
const changePage = async (page: any) => {
    page.value = page;
    await fetchWarehouses();
};

const goToDetail = (id: number) => {
    router.push({ name: 'WarehouseEdit', params: { id: id } })
}

onMounted(async () => {
    await fetchWarehouses();
})
</script>

<style scoped>
th input {
    width: unset;
}

.form-control {
    border-radius: 6px !important;
}

.input-group i {
    font-size: 20px;
    color: #999593;
}

.dropdown-menu {
    min-width: 50px;

}

.dropdown-item {
    color: #999593;
    font-weight: 700;
    font-size: 13px;
    padding: 5px 15px;
}

.dropdown-toggle::after {
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}
</style>