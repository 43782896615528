
import { ActionContext, Module } from 'vuex';
import { RootState } from '../index';

interface BankData {
  name: string;
}

interface State {
  selectedBank: BankData;
  banks: BankData[];
}

const state = (): State => ({
  selectedBank: { name: 'Ziraat' },
  banks: [
    { name: 'Akbank' },
    { name: 'Ziraat' },
    { name: 'Garanti' },
  ],
});

const mutations = {
  setBank(state: State, bank: BankData) {
    state.selectedBank = bank;
  },
};

const actions = {
  updateSelectedBank({ commit }: ActionContext<State, RootState>, bank: BankData) {
    commit('setBank', bank);
  },
};

const getters = {
  getSelectedBank: (state: State) => state.selectedBank,
};

const bankModule: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default bankModule;
