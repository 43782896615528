import WarehousesView from "@/views/Warehouses/WarehousesView.vue";

export const WarehousesRoutes = [
  {
    path: "/warehouses",
    name: "WarehousesList",
    component: WarehousesView,
  },
  {
    path: "/warehouses/create",
    name: "WarehouseCreate",
    component: () => import("@/views/Warehouses/WarehousesForm.vue"),
  },
  {
    path: "/warehouses/edit/:id",
    name: "WarehouseEdit",
    component: () => import("@/views/Warehouses/WarehousesForm.vue"),
  },
  {
    path: "/warehouses/detail/:id",
    name: "WarehouseDetail",
    component: () => import("@/views/Warehouses/WarehousesDetail.vue"),
  },
  {
    path: "/warehouses/transfer",
    name: "WarehouseTransfer",
    component: () =>
      import("@/views/WarehousesTransfer/WarehousesTransferView.vue"),
  },
  {
    path: "/warehouses/transfer/create",
    name: "WarehouseTransferCreate",
    component: () =>
      import("@/views/WarehousesTransfer/WarehousesTransferForm.vue"),
  },
];
