import { OperationType } from "@/types/Safes";

export const operationTypeHelper = (operationType: OperationType) => {
  console.log(operationType);
  switch (operationType) {
    case "sale_invoice":
      return "Satış Faturası";
    case "purchase_invoice":
      return "Alış Faturası";
    case "income":
      return "Müşteriden Tahsilat";
    case "expense":
      return "Gider";
    case "contact_income":
      return "Cari Tahsilat";
    case "contact_expense":
      return "Cari Ödeme";
    case "Transfer":
      return "Transfer";
    case "Opening Balance":
      return "Açılış Bakiyesi";
    case "Closing Balance":
      return "Kapanış Bakiyesi";
    default:
      return "";
  }
};

export const dateHelper = (date: string | undefined) => {
  const newDate = date ? new Date(date).toLocaleString() : "-";
  return newDate;
};
