import CustomerGroup from "@/views/CustomerGroups/CustomerGroup.vue";
export const CustomerGroupsRoutes = [
  {
    path: "/customer-groups",
    name: "CustomerGroupsList",
    component: CustomerGroup,
  },
  {
    path: "/customer-groups/create",
    name: "CustomerGroupsCreate",
    component: () => import("@/views/CustomerGroups/CustomerGroupsForm.vue"),
  },
  {
    path: "/customer-groups/edit/:id",
    name: "CustomerGroupsEdit",
    component: () => import("@/views/CustomerGroups/CustomerGroupsForm.vue"),
  },
];
