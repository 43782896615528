import ProductsView from "@/views/Products/ProductsView.vue";

export const ProductsRoutes = [
  {
    path: "/products",
    name: "ProductsList",
    component: ProductsView,
  },
  {
    path: "/products/create",
    name: "ProductCreate",
    component: () => import("@/views/Products/ProductsForm.vue"),
  },
  {
    path: "/products/edit/:id",
    name: "ProductEdit",
    component: () => import("@/views/Products/ProductsForm.vue"),
  },
  {
    path: "/products/detail/:id",
    name: "ProductDetail",
    component: () => import("@/views/Products/ProductsDetail.vue"),
  },
];
