import { ActionContext, Module } from 'vuex';
import { RootState } from '../index'; 


interface StoreData {
    storeName?: string;
    address?: string;
    city?: string;
    town?: string;
}

interface State {
  storeData: StoreData | null;
  stores: StoreData[];
}


const state = (): State => ({
  storeData: {
      storeName: '',
      address: '',
      city: '',
      town: '',
  },
  stores:[],
});

const mutations = {
  setStoreData(state: State, data: StoreData) {
    state.storeData = data;
  },
  addStore(state: State, newStoreData: StoreData) {
    state.stores.push(newStoreData);
  },
};


const actions = {
  updateStoreData({ commit }: ActionContext<State, RootState>, data: StoreData) {
    commit('setStoreData', data);
  },
  addNewStore({ commit }: ActionContext<State, RootState>, newStoreData: StoreData) {
    commit('addStore', newStoreData);
  },
};


const getters = {
  getStoreData: (state: State) => state.storeData,
};

const storeModule: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default storeModule;
