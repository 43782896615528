import SaleInvoiceView from "@/views/SaleInvoice/SaleInvoiceView.vue";

export const SaleInvoiceRoutes = [
  {
    path: "/sale-invoice",
    name: "saleInvoiceList",
    component: SaleInvoiceView,
  },
  {
    path: "/sale-invoice/:id",
    name: "saleInvoiceDetail",
    component: SaleInvoiceView,
  },
  {
    path: "/sale-invoice/create",
    name: "createSale",
    component: () => import("@/views/SaleInvoice/SaleInvoiceForm.vue"),
  },
  {
    path: "/sale-invoice/edit/:id",
    name: "saleInvoiceEdit",
    component: SaleInvoiceView,
  },
];
