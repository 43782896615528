<template>
    <div class="container">
        <div class="form-g-wrapper">
            <form @submit.prevent="onSubmit">
                <div class="inner-container d-flex flex-column gap-5">
                    <div class="form-separator">
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3">
                                    <div class="form-title d-flex align-items-center gap-5">
                                        <i class="fa-solid fa-box-open"></i>
                                        <span class="text-uppercase">Ürün Adı</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input p-2" v-model="product.name" />
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column gap-5">
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-hashtag"></i>
                                    <span class="text-uppercase">stok kodu</span>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input p-2" v-model="product.stock_code" />
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-paperclip"></i>
                                    <span class="text-uppercase">açıklama</span>
                                </div>
                                <div class="col-6">
                                    <textarea type="text" class="form-input p-2 w-100"
                                        v-model="product.description"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-store"></i>
                                    <span class="text-uppercase">depo</span>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-lg mb-3" v-model="product.warehouse_id"
                                        @change="fetchShelves">
                                        <option>Seçiniz</option>
                                        <option v-for="warehouse in warehouses" :key="warehouse.id"
                                            :value="warehouse.id">
                                            {{ warehouse.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper" v-if="shelves.length > 0">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-store"></i>
                                    <span class="text-uppercase">Raf</span>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-lg mb-3" v-model="product.shelve_id">
                                        <option>Seçiniz</option>
                                        <option v-for="shelve in shelves" :key="shelve.id" :value="shelve.id">
                                            {{ shelve.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-barcode"></i>
                                    <span class="text-uppercase">barkod</span>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input p-2" v-model="product.barcode" />
                                </div>
                                <div class="col-3 d-flex aling-items-center gap-4">
                                    <button type="button"
                                        class="icon icon-blue d-flex align-items-center justify-content-center">
                                        <i class="fa-solid fa-camera"></i>
                                    </button>
                                    <button type="button"
                                        class="icon icon-blue d-flex align-items-center justify-content-center"
                                        @click="generateBarcode">
                                        <i class="fa-solid fa-plus"></i>
                                    </button>
                                    <button type="button"
                                        class="icon icon-green d-flex align-items-center justify-content-center">
                                        <i class="fa-solid fa-print"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper" v-if="!gtipShow">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">

                                </div>
                                <div class="col-6">
                                    <div class="day-button-wrap d-flex align-items-center ">
                                        <button class="btn btn-secondary outline-btn" @click="gtipShow = !gtipSHow">
                                            <i class="fa-solid fa-plus"></i>
                                            GTIP ekle
                                        </button>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper" v-if="gtipShow">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-barcode"></i>
                                    <span class="text-uppercase">gtip</span>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input p-2" v-model="product.gtip" />
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-calendar-check"></i>
                                    <span class="text-uppercase">Garanti Bitimi</span>
                                </div>
                                <div class="col-6">
                                    <DateInput class="form-input p-2" v-model="product.warranty_date" />
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-clock"></i>
                                    <span class="text-uppercase">Son Kullanma Tarihi</span>
                                </div>
                                <div class="col-6">
                                    <DateInput class="form-input p-2" v-model="product.expiration_date" />  
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-circle-info"></i>
                                    <span class="text-uppercase">model/marka</span>
                                </div>
                                <div class="col-6 d-flex gap-4">
                                    <div class="input-group">
                                        <label class="input-group-text" for="inputGroupSelect01">Model</label>
                                        <select class="form-select" id="inputGroupSelect01" v-model="product.model_id">
                                            <option selected>Seçiniz</option>
                                            <option v-for="model in models" :key="model.id" :value="model.id">
                                                {{ model.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="input-group">
                                        <label class="input-group-text" for="inputGroupSelect01">Marka</label>
                                        <select class="form-select" id="inputGroupSelect01" v-model="product.brand_id">
                                            <option selected>Seçiniz</option>
                                            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                                                {{ brand.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="input-group">
                                        <label class="input-group-text" for="inputGroupSelect01">Kategori</label>
                                        <select class="form-select" id="inputGroupSelect01"
                                            v-model="product.category_id">
                                            <option selected>Seçiniz</option>
                                            <option v-for="category in categories" :key="category.id"
                                                :value="category.id">
                                                {{ category.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-coins"></i>
                                    <span class="text-uppercase">para birimi</span>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-lg mb-3" v-model="product.currency_id">
                                        <option selected>Seçiniz</option>
                                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                                            {{ currency.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-coins"></i>
                                    <span class="text-uppercase">alış satış birimi</span>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-lg mb-3" v-model="product.unit_id">
                                        <option selected>Seçiniz</option>
                                        <option v-for="unit in units" :key="unit.id" :value="unit.id">
                                            {{ unit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-tag"></i>
                                    <span class="text-uppercase">vergi</span>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-lg mb-3" v-model="product.tax_id"
                                        @change="calculate">
                                        <option selected>Seçiniz</option>
                                        <option v-for="tax in taxes" :key="tax.id" :value="tax.id">
                                            {{ tax.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-3 d-flex aling-items-center gap-4">
                                    <div class="btn-group btn-drop">
                                        <button type="button"
                                            class="icon icon-blue d-flex align-items-center justify-content-center"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                        <ul class="dropdown-menu pt-3 pb-3">
                                            <li @click="extraTaxes.excise = !extraTaxes.excise">
                                                ÖTV EKLE
                                            </li>
                                            <li @click="
                                                extraTaxes.communication = !extraTaxes.communication
                                                ">
                                                ÖİV EKLE
                                            </li>
                                            <li @click="
                                                extraTaxes.accommodation = !extraTaxes.accommodation
                                                ">
                                                KONAKLAMA VER. EKLE
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Ekstra Vergiler -->
                    <div class="form-separator d-flex flex-column gap-5">
                        <div class="form-wrapper" v-if="extraTaxes.excise">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-tag"></i>
                                    <span class="text-uppercase">ötv</span>
                                </div>
                                <div class="col-5">
                                    <input type="text" class="form-input p-2" v-model="product.excise_duty" />
                                </div>
                                <div class="col-3">
                                    <select class="form-input" v-model="product.excise_duty_type">
                                        <option selected>Seçiniz</option>
                                        <option value="percentage">%</option>
                                        <option value="amount">₺</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper" v-if="extraTaxes.communication">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-tag"></i>
                                    <span class="text-uppercase">öiv</span>
                                </div>
                                <div class="col-5">
                                    <input type="text" class="form-input p-2" v-model="product.communications_tax" />
                                </div>
                                <div class="col-3">
                                    <select class="form-input" v-model="product.communications_tax_type">
                                        <option selected>Seçiniz</option>
                                        <option value="percentage">%</option>
                                        <option value="amount">₺</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper" v-if="extraTaxes.accommodation">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-tag"></i>
                                    <span class="text-uppercase">konaklama vergisi</span>
                                </div>
                                <div class="col-5">
                                    <input type="text" class="form-input p-2" v-model="product.accommodation_tax" />
                                </div>
                                <div class="col-3">
                                    <select class="form-input" v-model="product.accommodation_tax_type">
                                        <option selected>Seçiniz</option>
                                        <option value="percentage" selected>%</option>
                                        <option value="amount">₺</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Resimler -->
                    <div class="form-separator d-flex flex-column gap-5">
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-images"></i>
                                    <span class="text-uppercase">resimler</span>
                                </div>
                                <div class="col-6 card">
                                    <ImageUploader :api-url="'https://stage-backend2.vemasoftware.com/upload'"
                                        :model-value="media.saved" @update:model-value="uploadSuccess" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-separator d-flex flex-column gap-5">
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-clipboard-list"></i>
                                    <span class="text-uppercase">stok takibi</span>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-lg mb-3" v-model="product.stock_tracking">
                                        <option selected>Seçiniz</option>
                                        <option value="true">Evet</option>
                                        <option value="false">Hayır</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-clipboard-list"></i>
                                    <span class="text-uppercase">Lot Kullan</span>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-lg mb-3" v-model="product.lot_tracking">
                                        <option selected>Seçiniz</option>
                                        <option :value="true">Evet</option>
                                        <option :value="false">Hayır</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-exclamation-triangle"></i>
                                    <span class="text-uppercase">kritik stok uyarısı</span>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-lg mb-3" v-model="product.stock_alert">
                                        <option selected>Seçiniz</option>
                                        <option value="true">Evet</option>
                                        <option value="false">Hayır</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-separator d-flex flex-column gap-5">
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-tags"></i>
                                    <span class="text-uppercase">vergiler hariç alış fiyatı</span>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input p-2" v-model="product.purchase_price"
                                        @keyup="calculate" />
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-money-bill-wave"></i>
                                    <span class="text-uppercase">vergiler hariç satış fiyatı</span>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input p-2" v-model="product.sale_price"
                                        @keyup="calculate" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-separator d-flex flex-column gap-5">
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-tags"></i>
                                    <span class="text-uppercase">vergiler dahil alış fiyatı</span>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input p-2"
                                        v-model="product.tax_included_purchase_price" @keyup="calculate" />
                                </div>
                            </div>
                        </div>
                        <div class="form-wrapper">
                            <div class="row">
                                <div class="col-3 d-flex gap-5 align-items-center">
                                    <i class="fa-solid fa-balance-scale"></i>
                                    <span class="text-uppercase">vergiler dahil satış fiyatı</span>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input p-2" v-model="product.tax_included_sale_price"
                                        @keyup="calculate" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button-wrapper d-flex align-items-center justify-content-end mt-4">

                        <button type="button" @click="goBack()" class="btn btn-secondary me-2">
                            <i class="fa fa-times me-2"></i>
                            Vazgeç
                        </button>
                        <button type="submit" class="btn btn-success">
                            <i class="fa fa-save me-2"></i>
                            Kaydet</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts" setup>
import ImageUploader from "@/components/ImageUploader.vue";
import DateInput from "@/components/ui/DateInput.vue";
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, defineEmits } from "vue";

import { getUnits, getTaxes, getCurrencies } from "@/services/UtilsService";
import { getBrands } from "@/services/BrandsService";
import { getModels } from "@/services/ModelsService";
import {
    getShelvesByWarehouseId,
    getWarehouses,
} from "@/services/WarehousesService";
import {
    getProduct,
    createProduct,
    updateProduct,
} from "@/services/ProductsServices";

import { Units, Tax, Currency } from "@/types/UtilsTypes";
import { Brands } from "@/types/Brands";
import { Models } from "@/types/Models";
import { Warehouses } from "@/types/Warehouses";
import { Products } from "@/types/ProductsTypes";

import * as yup from "yup";
import Swal from "sweetalert2";
import { handleApiError } from "@/helpers/errorHandling";
import { showSuccessMessage } from "@/helpers/swalHelper";
import { ProductCategories } from "@/types/ProductCategories";
import { getProductCategories } from "@/services/ProductCategoryService";
import { Shelves } from "@/types/Shelves";
import { getShelves } from "@/services/ShelvesService";

const router = useRouter();

const modelId = router.currentRoute.value.params.id as string;
const units = ref<Units[]>([]);
const taxes = ref<Tax[]>([]);
const currencies = ref<Currency[]>([]);
const brands = ref<Brands[]>([]);
const models = ref<Models[]>([]);
const warehouses = ref<Warehouses[]>([]);
const shelves = ref<Shelves[]>([]);
const categories = ref<ProductCategories[]>([]);
const gtipShow = ref(false);

const extraTaxes = reactive({
    communication: false,
    accommodation: false,
    excise: false,
});

const emit = defineEmits(['close', 'saved']);

const media = reactive({
    saved: [] as File[],
    added: [] as File[],
    deleted: [] as File[],
});

let images = ref([]);

const product = ref<Products>({
    name: "",
    stock_tracking: true,
    stock_alert: true,
    purchase_price: 0,
    sale_price: 0,
    unit_id: 0,
    tax_id: 0,
    type: "product",
    currency_id: 0,
    stock: 0,
    lot_tracking: false,
    media: [],
});

const schema = yup.object({
    name: yup
        .string()
        .required()
        .label("Ad")
        .min(2, "En az 2 karakter olmalıdır")
        .max(50, "En fazla 50 karakter olmalıdır"),
    stock_tracking: yup.boolean().required().label("Stok Takibi"),
    unit_id: yup.number().required().label("Birim"),
    stock: yup.number().required().label("Stok"),
    purchase_price: yup.number().required().label("Alış Fiyatı"),
    sale_price: yup.number().required().label("Satış Fiyatı"),
    currency_id: yup.number().required().positive().label("Para Birimi"),
});

const uploadSuccess = (response: any) => {
    media.added = response;
};

const onSubmit = async () => {
    let response;
    try {
        const isValid = await validate(product.value);
        if (!isValid) return;

        product.value.type = "product";
        const images = [];

        if (media.added.length > 0) {
            for (let i = 0; i < media.added.length; i++) {
                images.push({
                    url: media.added[i].url,
                    featured: media.added[i].featured,
                });
            }
        }

        if (media.saved.length > 0) {
            //Eğer aynı resim daha önce eklenmiş ise ekleme 
            for (let i = 0; i < media.saved.length; i++) {
                if (!images.find((image) => image.url === media.saved[i].url)) {
                    images.push({
                        url: media.saved[i].url,
                        featured: media.saved[i].featured,
                    });
                }
            }
        }

        product.value.media = images;

        if (modelId) {
            response = await updateProduct(modelId, product.value);
        } else {
            response = await createProduct(product.value);
        }
        showSuccessMessage('Ürün başarıyla kaydedildi');
        emit('saved', response.data.id);
        emit('close');
    } catch (error) {
        handleApiError(error);
    }
};

const validate = async (data: Brands) => {
    try {
        await schema.validate(data, { abortEarly: false });
        return true;
    } catch (error: any) {
        console.error(error.errors);
        Swal.fire({
            icon: "error",
            title: "Hata",
            text: error.errors[0],
        });
        return false;
    }
};

const changeMedia = (files: File[]) => {
    console.log(files);
    media.added = files;
}
const initMedia = () => {
    console.log(product.value.media);

};


const calculate = () => {
    const {
        purchase_price = 0,
        sale_price = 0,
        tax_id,
    } = product.value as {
        purchase_price?: number;
        sale_price?: number;
        tax_id: number;
    }; // Destructuring for efficiency
    const tax = taxes.value.find((tax) => tax.id === tax_id);
    const tax_rate = tax?.rate || 0; // Nullish coalescing for safer default

    const purchaseTaxAmount = (purchase_price * tax_rate) / 100; // Calculate tax amount
    product.value.tax_included_purchase_price =
        parseInt(purchase_price.toString()) +
        parseInt(purchaseTaxAmount.toString());

    const saleTaxAmount = (sale_price * tax_rate) / 100; // Calculate tax amount
    product.value.tax_included_sale_price =
        parseInt(sale_price.toString()) + parseInt(saleTaxAmount.toString());
};

const generateBarcode = () => {
    const barcode = Math.floor(Math.random() * 1000000000000);
    product.value.barcode = barcode.toString() as unknown as string;
};

onMounted(async () => {
    try {
        const data = await getUnits();
        units.value = data.data;

        const data2 = await getTaxes();
        taxes.value = data2.data;

        const data3 = await getBrands();
        brands.value = data3.data;

        const data4 = await getModels();
        models.value = data4.data;

        const data5 = await getWarehouses();
        warehouses.value = data5.data;

        const data6 = await getCurrencies();
        currencies.value = data6.data;

        const data7 = await getProductCategories();
        categories.value = data7.data;

        if (modelId) {
            const data = await getProduct(modelId);
            console.log(data.data);
            product.value = { ...data.data };
            if (product.value.accommodation_tax) {
                extraTaxes.accommodation = true;
            }
            if (product.value.communications_tax) {
                extraTaxes.communication = true;
            }
            if (product.value.excise_duty) {
                extraTaxes.excise = true;
            }
            product.value.media = JSON.parse(product.value.media);
            for (let i = 0; i < product.value.media.length; i++) {
                media.saved.push({
                    name: product.value.media[i].name,

                });
            }

            calculate();
        }
    } catch (error) {
        console.error(error);
        handleApiError(error);
    }
});

const fetchShelves = async (warehouseId: string) => {
    try {
        const warehouseId = product.value.warehouse_id;
        const data = await getShelves();
        shelves.value = data.data;
    } catch (error) {
        handleApiError(error);
    }
};

const goBack = () => {
    emit('close');
};
</script>

<style scoped>
.image-upload-container {
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;
    padding: 1rem;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

@media (min-width: 640px) {
    .image-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 768px) {
    .image-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.image-item {
    position: relative;
    aspect-ratio: 1 / 1;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.image-item:hover {
    transform: scale(1.05);
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.remove-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: #ef4444;
    color: white;
    border: none;
    border-radius: 9999px;
    padding: 0.25rem;
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;
}

.image-item:hover .remove-button {
    opacity: 1;
}

label {
    border: 1px solid #999593;
    border-right-width: 0px;
    border-radius: 0;
    font-weight: 600;
    color: #999593;
    font-size: var(--fs-14);
}

.icon {
    width: 36px;
    height: 36px;
    border-radius: 6px;
}

.icon i {
    color: #fff;
}

.icon-green {
    background-color: green;
}

.icon-blue {
    background: var(--blue-gradient);
}

.btn-drop .dropdown-menu {
    min-width: 200px;
}

.table-dropdown .dropdown-toggle::after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.table-dropdown .dropdown-toggle,
.small-drop {
    height: 33px;
    border: 1px solid #949494;
    text-align: left;
    padding-left: 10px;
}

.small-drop {
    width: 100px;
}

.small-drop .prepend {
    padding: 0 5px 0 7px;
    line-height: 28px;
    font-size: 1.3rem;
    font-weight: 600;
    color: #999593;
    white-space: nowrap;
    background-color: #dedddc;
    border-right: 1px solid #c5c4c2;
}

.small-drop .dropdown-menu {
    width: 52px;
    font-size: 15px;
}

.small-drop .dropdown-menu li {
    transition: 0.5s;
    cursor: pointer;
    padding: 5px 15px;
}

.table-dropdown .dropdown-menu {
    min-width: 260px;
}

.table-dropdown .table-scroll-drop {
    max-height: 270px;
}
</style>