import api, { ApiResponse } from "./ApiService";

export async function getHomeStatistics(): Promise<any> {
  try {
    const response = await api.get(`/statistics/home`);
    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}
