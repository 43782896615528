<template>
    <input type="date" :value="formattedDate" :class="props.class" />
</template>

<script setup lang="ts">
import { ref, watch, computed, defineProps, defineEmits, onMounted } from 'vue';

const props = defineProps({
    value: {
        type: String, 
    },
    class: {
        type: String,
        default: '',
    }
});

const emit = defineEmits(['update:value']);

// Bugünün tarihini hesapla
const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const day = String(today.getDate()).padStart(2, '0');
const defaultDate = `${year}-${month}-${day}`;

// Tarih state'ini oluştur
const formattedDate = ref( defaultDate);

// Props olarak gelen 'value' değiştiğinde 'formattedDate' güncellenir
watch(() => props.value, (newValue) => {
    formattedDate.value = newValue || defaultDate;
}, { immediate: true });

// Tarih input değeri güncellenir
const updateDate = (newValue: string) => {
    formattedDate.value = newValue;
    emit('update:value', newValue);
};

onMounted(() => {
  if (!props.value) {
    emit('update:value', formattedDate.value);
  }
});
</script>

<style scoped>
/* İstediğin stil düzenlemelerini buraya ekleyebilirsin */
</style>