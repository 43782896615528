import { Invoice } from "@/types/Invoices";
import api, { ApiResponse } from "./ApiService";
import { Documents } from "@/types/Documents";

export async function getReturnInvoices(
  params: {
    page?: number;
    pageSize?: number;
    search?: string;
  } = {}
): Promise<ApiResponse<Documents[]>> {
  try {
    const response = await api.get("/documents/list", {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        search: params.search,
        type: "return",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function getReturnInvoice(id: number): Promise<Invoice> {
  try {
    const response = await api.get(`/invoices/sale/${id}`);
    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function createReturnInvoice(invoice: Invoice) {
  try {
    const response = await api.post("/documents/create", {
      ...invoice,
      type: "return",
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
