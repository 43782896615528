<template>
    <CustomerForm />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import CustomerForm from '@/components/CustomerForm.vue'

const route = useRoute()
const customerId = ref(route.params.id)


</script>