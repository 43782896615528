<template>
  <header class="head-wrap pb-4 pb-md-0">
    <div class="row d-md-flex align-items-center justify-content-between p-3 p-lg-0 mb-3">
     
      <div class="col-lg-2 mt-3">
         <!-- 
        <div class="add-users-box d-flex align-items-center justify-content-between">
          <div class="add-user">
            <a href=""
              class="user-photo position-relative d-flex align-items-center justify-content-center flex-column">
              <img src="../assets/images/icons/add.svg" class="add-user-btn" alt="add icon" />
            </a>
            <span>Ekle</span>
          </div>
          <div class="add-user d-flex align-items-center justify-content-center flex-column">
            <img src="../assets/images/icons/Oval.svg" alt="" />
            <span>Adnan</span>
          </div>
          <div class="add-user d-flex align-items-center justify-content-center flex-column">
            <img src="../assets/images/icons/Oval.svg" alt="" />
            <span>Busecan</span>
          </div>
          <div class="add-user d-flex align-items-center justify-content-center flex-column">
            <img src="../assets/images/icons/Oval.svg" alt="" />
            <span>Rıfat</span>
          </div>
          <div class="add-user">
            <span
              class="user-photo position-relative d-flex align-items-center justify-content-center flex-column"></span>
            <span>Faik</span>
          </div>
        </div>-->
      </div>
      <div class="col-md-6 col-xl-4 col-xxl-3 mt-3 mb-md-4 mb-lg-0">
        <div class="exchange-rate-wrap d-flex align-items-center justify-content-around justify-content-lg-between">
          <div class="exchange-rate d-flex justify-content-between align-items-center">
            <div class="stock-exchange d-flex align-items-center">
              <div class="dollar-icon d-flex align-items-center justify-content-center">
                <img src="../assets/images/icons/dollar-1.svg" alt="" />
              </div>
              <div>
                <span>Alış</span>
                <div class="line"></div>
                <span>Satış</span>
              </div>
            </div>
            <div class="rate d-flex align-items-center">
              <div>
                <span>29.00</span>
                <div class="line"></div>
                <span>29.00</span>
              </div>
              <div class="refresh-box">
                <span class="refresh-icon d-flex align-items-center justify-content-center">
                  <!-- checkbox -->
                  <input type="checkbox" name="" id="" />
                </span>
                <span class="check-icon d-flex align-items-center justify-content-center">
                  <input type="checkbox" name="" id="" />
                </span>
              </div>
            </div>
          </div>
          <div class="exchange-rate rate-ragulation d-flex align-items-center justify-content-between">
            <div class="rate-text">
              <span>Kur</span>
              <span>Düzenleme</span>
            </div>
            <div class="current-rate d-flex">
              <div class="d-flex align-items-center justify-content-center flex-column">
                <span>27.00</span>
                <span>TL</span>
              </div>
              <div class="icon-box">
                <span class="refresh-icon"></span>
                <span class="check-icon"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mt-3 mb-md-4 mb-lg-0">
        <div class="other-operations d-flex align-items-center justify-content-between">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle d-flex align-items-center justify-content-center"
              type="button" id="speedBoxDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../assets/images/icons/time-fast1.svg" alt="time icon" />
              <span class="ms-2">Hızlı İşlemler</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="speedBoxDropdown">
              <li>
                <RouterLink :to="{ name: 'createSale' }" class="dropdown-item">Alış Faturası Oluştur</RouterLink>
              </li>
              <li>
                <RouterLink :to="{ name: 'saleInvoiceCreate' }" class="dropdown-item" href="#">Satış Faturası Oluştur
                </RouterLink>
              </li>
              <li>
                <RouterLink :to="{ name: 'createReturnInvoice' }" class="dropdown-item" href="#">İade Faturası Oluştur
                </RouterLink>
              </li>
            </ul>
          </div>

          <div class="switch">
            <span class="position-relative">
              <span class="switch-ball"></span>
              <img src="../assets/images/icons/switch-elements.svg" alt="" />
            </span>
          </div>
          <div class="chat d-flex align-items-center justify-content-center">
            <a href="">
              <img src="../assets/images/icons/Chat.svg" alt="chat icon" />
            </a>
          </div>
          <div class="chat notifications d-flex align-items-center justify-content-center">
            <a class="current-notification position-relative">
              <img class="notification" src="../assets/images/icons/notifications.svg" alt="notification" />
              <img src="../assets/images/icons/notification-bell-outline-interface-symbol.svg"
                alt="notification icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router';

defineComponent({
  name: 'HeaderComponent'
})
</script>