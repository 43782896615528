<template>
  <div class="col-lg-9">
    <div class="container collection-box-wrap pe-lg-0">
      <div class="collection-box activity-panel pb-3 pb-md-0">
        <div class="collection-header d-flex align-items-center justify-content-between">
          <h3 class="collection-title">Tahsilat</h3>
          <div class="d-flex align-items-center">
            <span class="collection-text d-flex align-items-center me-3">
              <img class="me-2" src="@/assets/images/icons/print.svg" alt="print icon" />
              (Yazdırılmamış 32 Rapor)
            </span>
            <span class="collection-text d-flex align-items-center" @click="isShow = !isShow">
              Tutarları Gizle
              <img class="ms-2" src="@/assets/images/icons/eye-crossed.svg" alt="eye icon" />
            </span>
          </div>
        </div>
        <div class="income-expense-statement d-md-flex align-items-center justify-content-between">
          <div class="income-expense-wrap endorsement">
            <h5 class="income-expense-title">
              <Skeleton width="10rem" height="4rem"></Skeleton>
              <span v-if="isShow">GELİR</span>
            </h5>

            <span class="amount">
              <span v-if="isShow">{{ format(statistics.income) || 0 }} {{ currency.symbol }}</span>
              <Skeletor v-if="!isShow" width="150" />
            </span>
          </div>
          <div class="income-expense-wrap expense">
            <h5 class="income-expense-title">
              <Skeletor v-if="!isShow" width="200" />
              <span v-if="isShow">GİDER</span>
            </h5>
            <span class="amount">
              <span v-if="isShow"> {{ format(statistics.expense) || 0 }} {{ currency.symbol }}</span>
              <Skeletor v-if="!isShow" width="150" />
            </span>
          </div>
          <div class="income-expense-wrap till">
            <h5 class="income-expense-title">
              <Skeletor v-if="!isShow" width="200" />
              <span v-if="isShow">KASA</span>
            </h5>
            <span class="amount">
              <span v-if="isShow"> {{ format(statistics.safe) || 0 }} {{ currency.symbol }}</span>
              <Skeletor v-if="!isShow" width="150" />
            </span>
          </div>
          <div class="debt-wrap">
            <div class="income-expense-wrap debt mb-0">
              <h5 class="income-expense-title">
                <Skeletor v-if="!isShow" width="200" />
                <span v-if="isShow"> ALINACAKLAR</span>
              </h5>
              <span class="amount">
                <span v-if="isShow"> {{ format(receive) || 0 }} {{ currency.symbol }}</span>
                <Skeletor v-if="!isShow" width="150" />
              </span>
            </div>
            <div class="income-expense-wrap debt">
              <h5 class="income-expense-title">
                <Skeletor v-if="!isShow" width="200" />
                <span v-if="isShow"> ÖDENECEKLER</span>
              </h5>
              <span class="amount">
                <Skeletor v-if="!isShow" width="150" />
                <span v-if="isShow"> {{ format(pay || 0) }} {{ currency.symbol }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- activity-overview -->
      <div class="activity-overview-wrap activity-panel">
        <div class="activity-overview-box d-lg-flex justify-content-between">
          <div class="common-type act activity-overview">
            <div class="activity d-flex align-items-center justify-content-between">
              <div class="message p-2 mt-2 position-relative d-flex align-items-center type">
                <span class="activity-icon acti,v d-flex align-items-center justify-content-center"><img
                    src="@/assets/images/icons/envelopes.svg" alt="mail-icon" /></span>
                <span class="activity-type">Mesaj</span>
              </div>
              <div class="comment position-relative d-flex align-items-center type">
                <span class="activity-icon d-flex align-items-center justify-content-center"><img
                    src="@/assets/images/icons/comments.svg" alt="comment-icon" /></span>
                <span class="activity-type">Yorum</span>
              </div>
            </div>
            <div class="activity-scroll">
              <div class="user-actions-list first-action active d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
              <div class="user-actions-list d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
              <div class="user-actions-list d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
              <div class="user-actions-list d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
              <div class="user-actions-list d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
              <div class="user-actions-list d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
            </div>
          </div>
          <div class="common-type act">
            <div class="activity d-flex align-items-center justify-content-between">
              <div class="position-relative d-flex align-items-center type">
                <span class="activity-icon d-flex align-items-center justify-content-center"><img
                    src="@/assets/images/icons/box-open-full.svg" alt="box-icon" /></span>
                <span class="activity-type">Sipariş</span>
              </div>
              <div class="position-relative d-flex align-items-center type">
                <span class="activity-icon d-flex align-items-center justify-content-center"><img
                    src="@/assets/images/icons/undo.svg" alt="undo-icon" /></span>
                <span class="activity-type">İadeler</span>
              </div>
            </div>
            <div class="activity-scroll">
              <div class="user-actions-list first-action active d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
              <div class="user-actions-list d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
              <div class="user-actions-list d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
              <div class="user-actions-list d-flex align-items-center">
                <div class="user-img position-relative">
                  <span class="active-passive active"></span>
                </div>
                <div class="user-name">
                  <span>Melisa Buket</span>
                  <p class="user-info">Buradan yazamıyor...</p>
                </div>
                <div class="time-wrap">
                  <span class="time">11:50</span>
                  <span class="num-badge d-flex align-items-center justify-content-center">5</span>
                </div>
              </div>
            </div>
          </div>
          <div class="common-type act activity-overview-last">
            <div class="activity last d-flex align-items-center justify-content-between">
              <div class="position-relative d-flex align-items-center type">
                <span class="activity-icon d-flex align-items-center justify-content-center"><img
                    src="@/assets/images/icons/rectangle-xmark.svg" alt="xmark-icon" /></span>
                <span class="activity-type">İptaller</span>
              </div>
              <div class="position-relative d-flex align-items-center type">
                <span class="activity-icon d-flex align-items-center justify-content-center"><img
                    src="@/assets/images/icons/chat-arrow-down.svg" alt="down-icon" /></span>
                <span class="activity-type">Azalanlar</span>
              </div>
            </div>
            <div class="activity-scroll">
              <div class="user-actions-list passive first-action active d-flex align-items-center">
                <div class="user-img position-relative"></div>
                <div class="user-name">
                  <span>Macbook M1</span>
                  <p class="user-info">18.950TL</p>
                </div>
              </div>
              <div class="user-actions-list passive d-flex align-items-center">
                <div class="user-img position-relative">
                  <img src="" alt="" />
                </div>
                <div class="user-name">
                  <span>Macbook M1</span>
                  <p class="user-info">18.950TL</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- activity-panel -->
      <div class="activity-panel todo-wrap">
        <div class="row">
          <div class="d-md-flex justify-content-between">
            <div class="col-md-5 common-type act todo-box">
              <div class="to-do-name activity d-flex align-items-center justify-content-between position-relative">
                <div class="position-relative d-flex align-items-center type">
                  <span class="activity-icon d-flex align-items-center justify-content-center"><img
                      src="@/assets/images/icons/Category.svg" alt="category-icon" /></span>
                  <span class="activity-type ms-2">Yapılacaklar</span>
                </div>
                <a href="" class="position-relative d-flex align-items-center type">
                  <span class="activity-icon d-flex align-items-center justify-content-center"><img
                      src="@/assets/images/icons/Frame.svg" alt="frame-icon" />
                    <span class="activity-type see-all ms-2">Tümünü Gör</span>
                  </span>
                </a>
              </div>
              <span class="todo-line"></span>
              <div class="p-2 mb-3 todo-scroll">
                <div class="to-do d-flex">
                  <span class="radio-btn ms-2 position-relative"></span>
                  <div class="todo-info ms-3">
                    <span>Başlık Metni</span>
                    <p>Açıklama</p>
                  </div>
                </div>
                <div class="to-do d-flex">
                  <span class="radio-btn ms-2 position-relative"></span>
                  <div class="todo-info ms-3">
                    <span>Başlık Metni</span>
                    <p>Açıklama</p>
                  </div>
                </div>
                <div class="to-do d-flex">
                  <span class="radio-btn ms-2 position-relative"></span>
                  <div class="todo-info ms-3">
                    <span>Başlık Metni</span>
                    <p>Açıklama</p>
                  </div>
                </div>
                <div class="to-do d-flex">
                  <span class="radio-btn ms-2 position-relative"></span>
                  <div class="todo-info ms-3">
                    <span>Başlık Metni</span>
                    <p>Açıklama</p>
                  </div>
                </div>
                <div class="to-do d-flex">
                  <span class="radio-btn ms-2 position-relative"></span>
                  <div class="todo-info ms-3">
                    <span>Başlık Metni</span>
                    <p>Açıklama</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 common-type act right-todo-box">
              <div class="to-do-name activity d-flex align-items-center justify-content-between position-relative">
                <div class="position-relative type d-flex align-items-center">
                  <span class="activity-icon d-flex align-items-center justify-content-center"><img
                      src="@/assets/images/icons/notes.svg" alt="note-icon" /></span>
                  <span class="activity-type ms-2">Notlar</span>
                </div>
                <a href="" class="position-relative type d-flex align-items-center">
                  <span class="activity-icon d-flex align-items-center justify-content-center"><img
                      src="@/assets/images/icons/Frame.svg" alt="frame-icon" />
                    <span class="activity-type see-all ms-2">Tümünü Gör</span>
                  </span>
                </a>
              </div>
              <div class="note-wrap todo-scroll mb-3 p-2 d-flex justify-content-between flex-wrap">
                <div class="note position-relative">
                  <div class="p-4">
                    <span class="note-name">Note 1</span>
                    <p class="note-text">Açıklama metnini buraya yazabilirsiniz.</p>
                  </div>
                </div>
                <div class="note position-relative">
                  <div class="p-4">
                    <span class="note-name">Note 1</span>
                    <p class="note-text">Açıklama metnini buraya yazabilirsiniz.</p>
                  </div>
                </div>
                <div class="note position-relative">
                  <div class="p-4">
                    <span class="note-name">Note 1</span>
                    <p class="note-text">Açıklama metnini buraya yazabilirsiniz.</p>
                  </div>
                </div>
                <div class="note position-relative">
                  <div class="p-4">
                    <span class="note-name">Note 1</span>
                    <p class="note-text">Açıklama metnini buraya yazabilirsiniz.</p>
                  </div>
                </div>
                <div class="note position-relative">
                  <div class="p-4">
                    <span class="note-name">Note 1</span>
                    <p class="note-text">Açıklama metnini buraya yazabilirsiniz.</p>
                  </div>
                </div>
                <div class="note position-relative">
                  <div class="p-4">
                    <span class="note-name">Note 1</span>
                    <p class="note-text">Açıklama metnini buraya yazabilirsiniz.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- graph -->


      <div class="activity-panel graph-wrap">
        <div class="graph-box">
          <div class="graph-header d-flex align-items-center justify-content-between">
            <div class="days-button d-flex align-items-center justify-content-around">
              <span class="day d-flex align-items-center justify-content-center">Günlük</span>
              <span class="day d-flex align-items-center justify-content-center">Haftalık</span>
              <span class="day d-flex align-items-center justify-content-center">Hepsi</span>
            </div>
          </div>
          <div class="d-flex align-items-end">
            <span class="total-amount">TL {{ statistics.income || 0 }}</span>

          </div>
          <div class="row">
            <VueApexCharts type="area" height="350" :options="chartOptions" :series="series"></VueApexCharts>
          </div>
        </div>
      </div>

    </div>
  </div>

  <Rightside />
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import Rightside from '@/components/Rightside.vue';
import VueApexCharts from 'vue3-apexcharts';
import { getHomeStatistics } from '@/services/StatisticsService';
import { handleApiError } from '@/helpers/errorHandling';
import { format } from '@/helpers/priceHelper';
import { useStore } from 'vuex';

const store = useStore();

const currency = store.getters['getCurrency'];

const statistics = reactive({
  income: undefined,
  expense: 0,
  safe: 0,
});

const charts = reactive({
  income_label: [],
  income_series: [] as any,
});

const receive = ref(0);
const pay = ref(0);

const series = ref([]) as any;

const chartOptions = ref({
  chart: {
    height: 350,
    type: 'area',
    toolbar: {
      show: false
    }
  },


  xaxis: {
    type: 'datetime',
    categories: charts.income_label,
  },
  yaxis: {
    labels: {
      formatter: function (value: number) {
        return value + ' TL';
      }
    }
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy'
    },
  },
  toolbar: {
    show: false
  },
  languages: [
    'tr',
  ]
});

onMounted(async () => {
  try {
    const { data } = await getHomeStatistics();
    statistics.income = data.totalIncome;
    statistics.expense = data.totalExpenses;
    statistics.safe = data.totalSafe;

    receive.value = data.receive;
    pay.value = data.pay;

    charts.income_label = data.charts.incomeChart.labels.map((label: string) => new Date(label).getTime());
    charts.income_series = data.charts.incomeChart.series;

    //Charts için verileri düzenle
    const seriesData = [{
      name: 'Ciro',
      data: charts.income_series.map((value: string, index: number) => ({
        x: charts.income_label[index],
        y: Number(value)
      }))
    }];

    series.value = seriesData;
    chartOptions.value.xaxis.categories = charts.income_label;
  } catch (error) {
    console.log(error);
    handleApiError(error);
  }
});

const isShow = ref(true);
</script>
