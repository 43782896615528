import api, { ApiResponse } from "./ApiService";
import { Customer } from "@/types/Customer";
import { Ref } from "vue";
/**
 * @description Get All Customers
 * @param page
 * @param pageSize
 * @param groups
 * @param hasBalance
 * @returns Promise<Customer[]>
 *
 */

export async function getCustomers(
  params: {
    page?: number;
    pageSize?: number;
    groups?: number;
    hasBalance?: boolean;
    hasDebt?: boolean;
    search?: string | null;
  } = {}
): Promise<any> {
  const {
    page = 1,
    pageSize = 10,
    groups,
    hasBalance,
    hasDebt,
    search,
  } = params;
  try {
    const response = await api.get("/contacts/list", {
      params: {
        page,
        pageSize,
        groups,
        hasBalance,
        hasDebt,
        search,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Müşteri verileri alınamadı:", error);
    throw error;
  }
}

/**
 * @description Get Customer By Id
 * @param id
 * @returns Promise<Customer>
 */
export async function getCustomerById(
  id: number | undefined,
  only = false
): Promise<Customer> {
  let response;
  try {
    if (only) {
      response = await api.get(`/contacts/show/${id}?only=true`);
    } else {
      response = await api.get(`/contacts/show/${id}`);
    }
    return response.data.data as Customer;
  } catch (error) {
    console.error("Müşteri verileri alınamadı:", error);
    throw error;
  }
}

export async function getCustomerTransactions(
  id: number | undefined
): Promise<any> {
  try {
    const response = await api.get(`/contacts/transactions/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Müşteri işlemleri alınamadı:", error);
    throw error;
  }
}

/**
 * @description Create Customer
 * @param type
 * @param customer
 */
export async function createCustomer(customer: Customer) {
  try {
    const response = await api.post("/contacts/create", {
      ...customer,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/**
 * @description Customer update
 * @param customer
 */
export async function updateCustomer(customer: Customer) {
  const cleanCustomer = Object.fromEntries(
    Object.entries(customer).filter(([key, value]) => value != null)
  );

  try {
    const response = await api.put(`/contacts/update/${customer.id}`, {
      title: cleanCustomer.title,
      type: cleanCustomer.type,
      group_id: cleanCustomer.group_id,
      short_name: cleanCustomer.short_name,
      email: cleanCustomer.email,
      phone: cleanCustomer.phone,
      fax: cleanCustomer.fax,
      address: cleanCustomer.address,
      city_id: cleanCustomer.city_id,
      county_id: cleanCustomer.county_id,
      currency_id: cleanCustomer.currency_id,
      zip_code: cleanCustomer.zip_code,
      tax_type: cleanCustomer.tax_type,
      tax_number: cleanCustomer.tax_number,
      tax_office: cleanCustomer.tax_office,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
