import Swal from "sweetalert2";

export async function showConfirmationDialog() {
  return Swal.fire({
    title: "Emin misiniz?",
    text: "Bu işlem geri alınamaz!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#999593",
    confirmButtonText: "Evet, sil!",
    cancelButtonText: "Hayır",
  });
}

export async function showSuccessMessage(message: string) {
  Swal.fire({
    icon: "success",
    title: message,
    showConfirmButton: false,
    timer: 1500,
  });
}
