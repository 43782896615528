<template>
    <tbody>
        <tr>
            <th scope="row">
                <div class="d-flex align-items-center justify-content-center icon-box">
                    <Skeletor circle size="24" />
                </div>
            </th>
            <td>
                <div class="d-flex flex-column gap-1">
                    <div class="user-name">
                        <Skeletor width="100px" />
                    </div>
                    <div class="user-info d-flex gap-2">
                        <span class="e-mail">
                            <Skeletor width="150px" />
                        </span>

                        <span class="phone">
                            <Skeletor width="100px" />
                        </span>
                    </div>
                </div>
            </td>
            <td>
                <div>
                    <Skeletor width="80px" />
                </div>
            </td>
            <td class="text-end">
                <Skeletor width="80px" />
            </td>
        </tr>
    </tbody>
</template>
<script setup lang="ts">
import { defineComponent } from 'vue';

defineComponent({
    name: 'CustomerSkelator',
    props: {
        tdCount: Number
    }
})
</script>

<style scoped>
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.input-group i {
    font-size: 20px;
    color: #999593;
}

.filter-btn {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    padding: 1.5px 30px 1.5px 15px;
    outline: 0;
    background-color: #d2d0cf;
    line-height: 40px;
    font-size: 1.3rem;
    font-weight: 600;
    color: #999593;
    transition: 0.5s;
}

.dropdown-toggle::after {
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}


.icon-box {
    width: 40px;
    height: 40px;
    line-height: 39px;
    font-size: 2.4rem;
    background-color: #dedddc;
    border-radius: 50%;
    text-align: center;
    color: #a39f9d;
}

.user-name::first-letter {
    text-transform: uppercase;
}

.user-info {
    font-size: 13px;
}

.user-col {
    width: 70px;
}

.user-info-col {
    width: 20%;
}

.dropdown-menu {
    min-width: 220px;
}

.dropdown-item {
    color: #999593;
    font-weight: 700;
    font-size: 13px;
    padding: 5px 15px;
}
</style>