import api from "./ApiService";
import { City, County, Currency, Tax, Units } from "@/types/UtilsTypes";
import { ApiResponse } from "./ApiService";

/**
 * Get cities
 * @param id
 * @param search
 * @returns
 */
export async function getCities(params?: {
  id?: number;
  search?: number;
}): Promise<ApiResponse<City[]>> {
  try {
    const response = await api.get("utils/cities");
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Get Counties
 * @param city_id
 * @returns
 */
export async function getCounties(
  city_id: number
): Promise<ApiResponse<County[]>> {
  try {
    const response = await api.get(`utils/counties/${city_id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getCurrencies(): Promise<ApiResponse<Currency[]>> {
  try {
    const response = await api.get(`utils/currencies`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getTaxes(): Promise<ApiResponse<Tax[]>> {
  try {
    const response = await api.get(`utils/taxes`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getUnits(): Promise<ApiResponse<Units[]>> {
  try {
    const response = await api.get(`utils/units`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
