import ReturnInvoiceView from "@/views/ReturnInvoice/ReturnInvoiceView.vue";

export const ReturnInvoiceRoutes = [
  {
    path: "/return-invoice",
    name: "returnInvoiceList",
    component: ReturnInvoiceView,
  },
  {
    path: "/return-invoice/:id",
    name: "returnInvoiceDetail",
    component: ReturnInvoiceView,
  },
  {
    path: "/return-invoice/create",
    name: "createReturnInvoice",
    component: () => import("@/views/ReturnInvoice/ReturnInvoiceForm.vue"),
  },
  {
    path: "/return-invoice/edit/:id",
    name: "returnInvoiceEdit",
    component: ReturnInvoiceView,
  },
];
