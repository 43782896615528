import { Invoice } from "@/types/Invoices";
import api, { ApiResponse } from "./ApiService";
import { Documents } from "@/types/Documents";

export async function getSaleInvoices(
  params: {
    page?: number;
    pageSize?: number;
    dueDateExpired?: boolean;
    dueDateExpiring?: boolean;
    paid?: boolean;
    search?: string;
    contact_id?: number;
  } = {}
): Promise<ApiResponse<Documents[]>> {
  try {
    const response = await api.get("/documents/list", {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        search: params.search,
        dueDateExpired: params.dueDateExpired,
        dueDateExpiring: params.dueDateExpiring,
        paid: params.paid,
        contact_id: params.contact_id,
        type: "sale",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function getSaleInvoice(id: number): Promise<ApiResponse<Document>> {
  try {
    const response = await api.get(`/documents/show/${id}`);
    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function createSaleInvoice(invoice: Invoice) {
  try {
    const response = await api.post("/invoices/sale/create", {
      current_id: invoice.current_id,
      currency_id: invoice.currency_id,
      description: invoice.description,
      total_amount: invoice.total_amount,
      net_total: invoice.net_total,
      gross_total: invoice.gross_total,
      total_vat: invoice.total_vat,
      exchange_rate: invoice.exchange_rate,
      payment_amount: invoice.payment_amount,
      date: invoice.date,
      due_date: invoice.payment_date,
      cash_sale: invoice.cash_sale,
      stock_movement: invoice.stock_movement,
      payment_due_date: invoice.payment_date,
      payment_date: invoice.payment_date,
      safe_id: invoice.safe_id,
      items: invoice.items,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
