import api, { ApiResponse } from "./ApiService";
import { Documents } from "@/types/Documents";

export async function getPurchaseInvoices(
  params: {
    page?: number;
    pageSize?: number;
    search?: string;
    dueDateExpiring?: boolean;
    dueDateExpired?: boolean;
    paid?: boolean;
  } = {}
): Promise<ApiResponse<Documents[]>> {
  try {
    const response = await api.get("/documents/list", {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        search: params.search,
        type: "purchase",
        dueDateExpiring: params.dueDateExpiring,
        dueDateExpired: params.dueDateExpired,
        paid: params.paid,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function getPurchaseInvoice(
  id: string
): Promise<ApiResponse<Documents>> {
  try {
    const response = await api.get(`/documents/show/${id}`);
    return response.data;
  } catch (error) {
    console.error("Api Error:", error);
    throw error;
  }
}

export async function createPurchaseInvoice(invoice: Document) {
  try {
    const response = await api.post("/documents/create", {
      ...invoice,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
