<template>
    <body data-theme="light">
        <div class="container-fluid">
            <div class="row">
                <Leftside />
                <!-- right-side -->
                <div class="col-lg-10 right-side-wrap p-0">
                    <div class="right-side">
                        <div class="warn-box d-flex">
                            <div class="warn-text d-flex align-items-center justify-content-center">
                                <span>Aboneliğinizin bitimine 14 gün kaldı!</span>
                                <span>Bayilere yönetim admin bildirimi atanacak</span>
                            </div>

                            <div class="live-support align-items-center justify-content-center d-none d-md-flex">
                                <img src="@/assets/images/icons/time-twenty-four-1.svg" alt="" />
                                <a class="ms-2">Canlı Destek</a>
                            </div>
                            <button class="button d-lg-none">
                                <img src="@/assets/images/icons/menu.png" alt="" />
                            </button>
                        </div>

                        <Header />

                        <!-- middle side -->
                        <div class="row">
                            <RouterView />
                            <Rightside v-if="isSidebarOpen" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>

<script setup lang="ts">
import { ref, defineComponent } from 'vue'
import Header from '../components/Header.vue'
import Leftside from '../components/Leftside.vue'
import Rightside from '../components/Rightside.vue'

const isSidebarOpen = ref(false);

defineComponent({
    name: 'MainLayout',
    setup() {
        return {
            isSidebarOpen
        };
    }
});
</script>
<style>
@import '@/assets/css/index.css';
@import '@/assets/css/index.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
@import 'flatpickr/dist/flatpickr.css';
</style>
  